import { makeStyles, Typography, Box } from '@material-ui/core';
import { DirectionsBus as BusIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrolleybusIcon } from 'src/assets/icons/24-trolleybus.svg';
import { ReactComponent as TramIcon } from 'src/assets/icons/tram.svg';
import {
  ITINERARY_LINE_COLOR_MAP,
  ITINERARY_LINE_TEXT_MAP
} from 'src/constants';
import { cutTransportName, getTooltipText } from '../../utils';

interface PropsType extends LegType {
  index: number;
}

const useStyles = (legColor: string) =>
  makeStyles(({ spacing, palette }) => ({
    legDot: {
      width: 30,
      height: 30,
      borderRadius: 15,
      backgroundColor: legColor,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      position: 'relative'
    },
    legText: {
      position: 'absolute',
      top: 32,
      fontWeight: 'bold'
    },
    legTooltip: {
      position: 'absolute',
      top: 60,
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
      background: palette.grey[900],
      color: 'white',
      whiteSpace: 'nowrap',
      borderRadius: 4,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -5,
        left: '50%',
        marginLeft: -6,
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: `6px solid ${palette.grey[900]}`
      }
    },
    icon: {
      color: palette.common.white
    }
  }));

const Leg = ({ startTime, route, index }: PropsType) => {
  const { t } = useTranslation('trip');
  const { shortName, textColor } = route as RouteType;
  const classes = useStyles(
    ITINERARY_LINE_COLOR_MAP[(route as RouteType).textColor]
  )();
  const ICON_MAP = {
    [ITINERARY_LINE_TEXT_MAP.BUS]: (
      <BusIcon fontSize="small" className={classes.icon} />
    ),
    [ITINERARY_LINE_TEXT_MAP.TRAM]: <TramIcon style={{ fill: 'white' }} />,
    [ITINERARY_LINE_TEXT_MAP.TROLLEYBUS]: (
      <TrolleybusIcon style={{ fill: 'white' }} height={24} width={24} />
    )
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.legDot}>
      {ICON_MAP[textColor]}
      <Typography variant="body1" className={classes.legText}>
        {cutTransportName(shortName)}
      </Typography>
      {index === 1 && (
        <Typography variant="body2" className={classes.legTooltip}>
          {getTooltipText(t, startTime)}
        </Typography>
      )}
    </Box>
  );
};

export default Leg;
