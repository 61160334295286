import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import tripReducers from 'src/views/TripView/store/reducers';
import tripSettingsReducers from 'src/views/TripSettingsView/store/reducers';
import mainLayoutReducers from 'src/layouts/MainLayout/store/reducers';
import snackbarReducers from './reducers/snackbarReducers';
import mapLimitsReducers from './reducers/mapLimitsReducers';
import loadingReducers from './reducers/loadingReducer';

const rootReducer = {
  oidc: oidcReducer,
  loading: loadingReducers,
  snackbar: snackbarReducers,
  mapLimits: mapLimitsReducers,
  ui: combineReducers({
    trip: tripReducers,
    tripSettings: tripSettingsReducers,
    mainLayout: mainLayoutReducers
  })
};

export default rootReducer;
