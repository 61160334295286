import { FC, Dispatch, SetStateAction, memo, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map as MapIcon } from 'react-feather';
import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { selectIsFavoritesShown } from 'src/views/TripSettingsView/store/selectors';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { selectIsAuthenticated } from 'src/store/selectors';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions';
import { PointType, AutocompletePointType } from '../../types';
import { SEARCH_SYMBOLS } from '../../const';
import { autocompletePointToSelectedPoint, getFavoriteId } from '../../utils';
import PointOption from './PointOption';

const UserAddresses = lazy(() => import('../UserAddresses'));
const FavoritePlacesList = lazy(() =>
  import('../Favorites/FavoritePlacesList')
);

type Props = {
  type: PointType;
  separator: string;
  isActive: boolean;
  setValue: Dispatch<SetStateAction<string>>;
  setPoint: (route: SelectedPointType) => void;
};

export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  listWrapper: {
    position: 'absolute',
    top: 90,
    left: 0,
    width: '100%',
    zIndex: 1002,
    overflowY: 'auto',
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    backgroundColor: palette.common.white,
    [breakpoints.up('sm')]: {
      top: 106
    },
    '&$activeUserAddress': {
      top: 95
    },
    '&$withClearAll': {
      top: 144
    }
  },
  hidden: {
    display: 'none'
  },
  withClearAll: {},
  activeUserAddress: {},
  selectOnMapButton: {
    display: 'flex',
    width: '100%',
    minHeight: 40,
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: spacing(1.5),
    borderBottom: `1px solid ${palette.background.default}`,
    [breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  btnText: {
    marginLeft: spacing(1.5),
    textTransform: 'uppercase'
  }
}));

const SuggestedPoints: FC<Props> = ({
  type,
  separator,
  isActive,
  setValue,
  setPoint
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('trip');
  const classes = useStyles();
  const { palette } = useTheme();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const activeUserAddress = useSelector(selectors.selectActiveUserAddress);
  const pointsList = useSelector(selectors.selectAutocomleteList);
  const isFavoritesShown = useSelector(selectIsFavoritesShown);
  const recentsList = useSelector(selectors.selectRecents);
  const favoritePlaces = useSelector(selectors.selectFavoritePlaces);
  const startPoint = useSelector(selectors.selectStartPoint);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const openSelectOnMap = () => dispatch(actions.setSelectOnMap(type));
  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const { height } = useWindowSize();
  const barHeight =
    height - (isDesktopScreen ? 220 : 165) + (activeUserAddress ? 28 : 0);

  return (
    <Box
      className={clsx(
        classes.listWrapper,
        activeUserAddress && classes.activeUserAddress,
        startPoint && destinationPoint && classes.withClearAll,
        !isActive && classes.hidden
      )}
      maxHeight={barHeight}
      boxShadow={4}
      onMouseDown={e => e.preventDefault()}
    >
      {separator.length > SEARCH_SYMBOLS ? (
        pointsList.map((route: AutocompletePointType) => (
          <PointOption
            key={route.properties.id}
            layer={route.properties.layer}
            point={autocompletePointToSelectedPoint(route)}
            fillField={setValue}
            setPoint={setPoint}
            separator={separator}
            favoriteId={getFavoriteId(
              autocompletePointToSelectedPoint(route),
              favoritePlaces
            )}
          />
        ))
      ) : (
        <>
          <Box
            className={classes.selectOnMapButton}
            role="button"
            onClick={openSelectOnMap}
          >
            <MapIcon size={20} color={palette.secondary.main} />
            <Typography
              variant="button"
              color="secondary"
              className={classes.btnText}
            >
              {t('selectOnMap')}
            </Typography>
          </Box>
          <Suspense fallback={null}>
            {!activeUserAddress && isAuthenticated && (
              <UserAddresses setPoint={setPoint} />
            )}
            {recentsList.map((recent: SelectedPointType, i: number) => (
              <PointOption
                key={`${recent.lat + i}`}
                layer="recent"
                point={recent}
                fillField={setValue}
                favoriteId={getFavoriteId(recent, favoritePlaces)}
                setPoint={setPoint}
              />
            ))}
            {isAuthenticated && isFavoritesShown && (
              <FavoritePlacesList setPoint={setPoint} />
            )}
          </Suspense>
        </>
      )}
    </Box>
  );
};

export default memo(SuggestedPoints);
