import { createReducer, ActionType } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import moment from 'moment';
import { find, omit } from 'lodash';
import storage from 'redux-persist/lib/storage';
import { RouteOptions } from 'src/types/enums';
import { LatLngLiteral } from 'leaflet';
import * as actions from './actions';
import {
  AutocompletePointType,
  ActiveFieldType,
  TimeSettings,
  TimeSettingsMode,
  UserAddressType,
  FavoritePlaceType,
  PointType,
  ViewModes,
  FavoriteRouteType
} from '../types';
import { DATE_FORMAT, RECENTS_AMOUNT, TIME_FORMAT } from '../const';

type ActionsTypes = ActionType<typeof actions>;

export type StateTypes = Readonly<{
  viewMode: ViewModes;
  selectedRoute: ItineraryType | null;
  selectedStop: SelectedStopType | null;
  autocomleteList: AutocompletePointType[];
  startPoint: SelectedPointType | null;
  destinationPoint: SelectedPointType | null;
  locationInfo: SelectedPointType | null;
  selectedOnMapPoint: ActiveFieldType;
  routeOption: RouteOptions;
  timeSettings: TimeSettings;
  recents: SelectedPointType[];
  favoritePlaces: FavoritePlaceType[];
  favoriteRoutes: FavoriteRouteType[];
  activeUserAddress: UserAddressType | null;
  userAddresses: {
    Home?: SelectedPointType;
    Work?: SelectedPointType;
  };
  userLocation: LatLngLiteral | null;
}>;

export const initialState: StateTypes = {
  viewMode: ViewModes.SEARCH_VIEW,
  selectedRoute: null,
  selectedStop: null,
  autocomleteList: [],
  startPoint: null,
  destinationPoint: null,
  selectedOnMapPoint: null,
  locationInfo: null,
  routeOption: RouteOptions.BEST_ROUTE,
  timeSettings: {
    mode: TimeSettingsMode.DEPART_NOW,
    date: moment().format(DATE_FORMAT),
    time: moment().format(TIME_FORMAT)
  },
  recents: [],
  favoritePlaces: [],
  favoriteRoutes: [],
  activeUserAddress: null,
  userAddresses: {},
  userLocation: null
};

const setRecent = (
  recents: SelectedPointType[],
  payload: SelectedPointType
) => {
  if (!payload?.lat && !payload?.lng) {
    return recents;
  }

  const recent = find(recents, r => r.name === payload.name);

  if (!recent) {
    if (recents.length >= RECENTS_AMOUNT) {
      recents.pop();
    }

    recents.unshift(payload);
  }

  return recents;
};

const reducers = createReducer<StateTypes, ActionsTypes>(initialState)
  .handleAction(actions.setViewMode, (state, { payload }) => ({
    ...state,
    viewMode: payload
  }))
  .handleAction(actions.setSelectedRoute, (state, { payload }) => ({
    ...state,
    selectedRoute: payload
  }))
  .handleAction(actions.getAutocompleteData.success, (state, { payload }) => ({
    ...state,
    autocomleteList: payload
  }))
  .handleAction(
    actions.setPoint,
    (state, { payload: { pointType, point } }) => ({
      ...state,
      [pointType === PointType.START
        ? 'startPoint'
        : 'destinationPoint']: point,
      recents: setRecent([...state.recents], point as SelectedPointType)
    })
  )
  .handleAction(actions.reversePoints, state => ({
    ...state,
    startPoint: state.destinationPoint,
    destinationPoint: state.startPoint
  }))
  .handleAction(actions.setSelectOnMap, (state, { payload }) => ({
    ...state,
    selectedOnMapPoint: payload,
    viewMode: ViewModes.SEARCH_VIEW
  }))
  .handleAction(
    actions.setPointFromMapOnMobile.success,
    (state, { payload }) => ({
      ...state,
      locationInfo: payload
    })
  )
  .handleAction(actions.setRouteOption, (state, { payload }) => ({
    ...state,
    routeOption: payload
  }))
  .handleAction(actions.setTimeSetting, (state, { payload }) => ({
    ...state,
    timeSettings: payload
  }))
  .handleAction(actions.getFavoritePlaces.success, (state, { payload }) => ({
    ...state,
    favoritePlaces: payload
  }))
  .handleAction(actions.createFavoritePlace.success, (state, { payload }) => ({
    ...state,
    favoritePlaces: [payload, ...state.favoritePlaces]
  }))
  .handleAction(actions.deleteFavoritePlace.success, (state, { payload }) => ({
    ...state,
    favoritePlaces: state.favoritePlaces.filter(({ id }) => id !== payload)
  }))
  .handleAction(actions.getFavoriteRoutes.success, (state, { payload }) => ({
    ...state,
    favoriteRoutes: payload
  }))
  .handleAction(actions.createFavoriteRoute.success, (state, { payload }) => ({
    ...state,
    favoriteRoutes: [payload, ...state.favoriteRoutes]
  }))
  .handleAction(actions.deleteFavoriteRoute.success, (state, { payload }) => ({
    ...state,
    favoriteRoutes: state.favoriteRoutes.filter(({ id }) => id !== payload)
  }))
  .handleAction(
    actions.setIsSuggestedRouteDetailsShown,
    (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          selectedRoute: payload,
          isSuggestedRouteDetailsShown: true
        };
      }

      return {
        ...state,
        isSuggestedRouteDetailsShown: false
      };
    }
  )
  .handleAction(actions.getUserAddresses.success, (state, { payload }) => {
    return {
      ...state,
      userAddresses: payload
    };
  })
  .handleAction(actions.deleteUserAddress.success, (state, { payload }) => {
    return {
      ...state,
      userAddresses: omit(state.userAddresses, payload)
    };
  })
  .handleAction(actions.addUserAddress.success, (state, { payload }) => {
    return {
      ...state,
      userAddresses: {
        ...state.userAddresses,
        [state.activeUserAddress as string]: payload
      },
      activeUserAddress: null,
      viewMode: ViewModes.SEARCH_VIEW
    };
  })
  .handleAction(actions.setActiveUserAddress, (state, { payload }) => ({
    ...state,
    activeUserAddress: payload,
    locationInfo: state.userAddresses[payload as UserAddressType] || null,
    viewMode: payload ? ViewModes.USER_ADDRESS_VIEW : ViewModes.SEARCH_VIEW
  }))
  .handleAction(actions.resetTrip, state => {
    return {
      ...state,
      selectedRoute: null,
      startPoint: null,
      destinationPoint: null,
      selectedOnMapPoint: null,
      locationInfo: null,
      timeSettings: {
        mode: TimeSettingsMode.DEPART_NOW,
        date: moment().format(DATE_FORMAT),
        time: moment().format(TIME_FORMAT)
      },
      isSuggestedRouteDetailsShown: false,
      activeUserAddress: null,
      viewMode: ViewModes.SEARCH_VIEW
    };
  })
  .handleAction(actions.setSelectedStop, (state, { payload }) => ({
    ...state,
    selectedStop: payload,
    viewMode: ViewModes.STOP_DETAILS_VIEW
  }))
  .handleAction(actions.resetPersistentData, state => ({
    ...state,
    recents: [],
    routeOption: RouteOptions.BEST_ROUTE
  }))
  .handleAction(actions.goBackUserAddresses, state => ({
    ...state,
    viewMode: ViewModes.SEARCH_VIEW,
    activeUserAddress: null
  }))
  .handleAction(actions.setLocationInfo, (state, { payload }) => ({
    ...state,
    locationInfo: payload
  }))
  .handleAction(actions.setUserLocation, (state, { payload }) => ({
    ...state,
    userLocation: payload
  }));

const persistConfig = {
  key: 'trip',
  storage,
  whitelist: ['recents', 'routeOption']
};

export default persistReducer(persistConfig, reducers);
