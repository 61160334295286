import { MutableRefObject } from 'react';

export enum Layers {
  Venue = 'venue',
  Stop = 'stop',
  Address = 'address',
  Street = 'street'
}

export type AutocompletePointType = {
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    id: string;
    gid: string;
    layer: Layers;
    source: string;
    source_id: string;
    housenumber: string;
    country: string;
    locality: string;
    name: string;
    accuracy: string;
    label: string;
    street: string;
    distance: number;
  };
};

export enum PointType {
  START = 'start',
  DESTINATION = 'destination',
  USER_ADDRESS = 'userAddress'
}

export type ActiveFieldType = PointType | null;

export enum UserAddressType {
  HOME = 'Home',
  WORK = 'Work'
}

export enum Modes {
  WALK = 'WALK',
  TRAM = 'TRAM',
  TROLLEYBUS = 'TROLLEYBUS',
  BUS = 'BUS',
  WAIT = 'WAIT'
}

export interface FavoritePlaceType {
  id: number;
  lng: number;
  lat: number;
  address?: string;
  name?: string;
}

export type AutocompleteResponse = {
  features: AutocompletePointType[];
};

export enum TimeSettingsMode {
  DEPART_NOW = 'DEPART_NOW',
  DEPART_AT = 'DEPART_AT',
  ARRIVE_BY = 'ARRIVE_BY'
}

export type TimeSettings = {
  mode: TimeSettingsMode;
  date: string;
  time: string;
};

export type SuggestedRouteWarning =
  | 'suggestedRoutesWarning'
  | 'noRoutesBetweenPointsWarning'
  | 'noRoutesFound'
  | null;

export interface POIErrorResponce extends Error {
  code: string;
}

export interface FavoriteRouteType {
  id: number;
  from: SelectedPointType;
  to: SelectedPointType;
}

export enum ViewModes {
  SEARCH_VIEW = 'SEARCH_VIEW',
  ROUTE_DETAILS_VIEW = 'ROUTE_DETAILS_VIEW',
  STOP_DETAILS_VIEW = 'STOP_DETAILS_VIEW',
  USER_ADDRESS_VIEW = 'USER_ADDRESS_VIEW',
  STOPS_LIST_VIEW = 'STOPS_LIST_VIEW',
  TIMETABLE = 'TIMETABLE'
}

export type Pattern = {
  realtimeArrival: number;
  serviceDay: number;
  realtime: boolean;
  trip: {
    pattern: {
      code: string;
    };
    gtfsId: string;
    route: RouteType;
  };
};

export type StopDetailsResponce = {
  stop: StopType & {
    stoptimesWithoutPatterns: Pattern[];
  };
};

export interface StopsListItem {
  gtfsId: string;
  name: string;
  lat: number;
  lon: number;
  stoptimesWithoutPatterns: Pattern[];
}

export interface StopsListRequest {
  pattern: {
    route: RouteType;
    stops: StopsListItem[];
  };
}

export type StopPatternsResponce = {
  stop: {
    lat: number;
    lon: number;
    patterns: {
      code: string;
      route: Omit<RouteType, 'id' | 'longName' | 'mode' | 'gtfsId'>;
    }[];
  };
};

export type TimetableResponce = {
  stop: {
    stopTimesForPattern: {
      serviceDay: number;
      scheduledArrival: number;
    }[];
  };
};

export type UserAddressMenu = {
  id: number;
  type: UserAddressType;
  value: string;
  addressRef: MutableRefObject<HTMLDivElement | null>;
  hover: boolean;
};

export type Coordinate = {
  lat: number;
  lng: number;
};

export type LocationPoint = Coordinate & {
  id: string;
};
