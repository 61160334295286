import { FC, memo } from 'react';
import { Circle, Star } from 'react-feather';
import { v4 as uuidv } from 'uuid';
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Divider
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import { FavoriteRouteType, PointType } from '../../types';
import { getPointName } from '../../utils';

type Props = {
  route: FavoriteRouteType;
};

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    marginBottom: spacing(2.5),
    alignItems: 'center',
    cursor: 'pointer'
  },
  dotsWrapper: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: spacing(2.5)
  },
  startDot: {
    minHeight: 6,
    minWidth: 6,
    fill: palette.success.main,
    color: palette.success.main
  },
  endDot: {
    minHeight: 6,
    minWidth: 6,
    fill: palette.secondary.main,
    color: palette.secondary.main
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: spacing(5.5),
    flex: 1
  },
  address: {
    marginBottom: spacing(0.5)
  },
  star: {
    color: palette.success.main,
    fill: palette.success.main
  },
  startWrapper: {
    marginRight: spacing(1.5)
  },
  dots: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
    overflowY: 'hidden',
    height: '100%'
  },
  dot: {
    minWidth: 2,
    minHeight: 2,
    color: palette.primary.light,
    fill: palette.primary.light,
    marginTop: 2
  },
  line: {
    width: 305,
    marginBottom: spacing(1.5),
    marginLeft: 'auto',
    marginRight: spacing(3)
  }
}));

const FavoriteRoute: FC<Props> = ({ route: { from, to, id } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      actions.setPoint({
        point: from,
        pointType: PointType.START
      })
    );
    dispatch(
      actions.setPoint({
        point: to,
        pointType: PointType.DESTINATION
      })
    );
  };

  const deleteFavorite = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(actions.deleteFavoriteRoute.request(id));
  };

  return (
    <>
      <Divider className={classes.line} />
      <Box className={classes.root} onClick={handleClick}>
        <Box className={classes.dotsWrapper} py={1}>
          <Circle size={4} className={classes.startDot} />
          <Box className={classes.dots}>
            {new Array(30).fill(1).map(() => (
              <Circle key={uuidv()} size={2} className={classes.dot} />
            ))}
          </Box>
          <Circle size={4} className={classes.endDot} />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography variant="subtitle2" color="textPrimary">
            {getPointName(from)}
          </Typography>
          {from?.address && from?.address !== from?.name && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.address}
            >
              {from?.address}
            </Typography>
          )}
          <Typography variant="body1" color="textPrimary">
            {getPointName(to)}
          </Typography>
          {to?.address && to?.address !== to?.name && (
            <Typography variant="caption" color="textSecondary">
              {to?.address}
            </Typography>
          )}
        </Box>
        <IconButton
          color="secondary"
          className={classes.startWrapper}
          onClick={deleteFavorite}
        >
          <Star size={24} className={classes.star} />
        </IconButton>
      </Box>
    </>
  );
};

export default memo(FavoriteRoute);
