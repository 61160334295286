import * as React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import {
  createStyles,
  WithStyles,
  withStyles,
  Theme
} from '@material-ui/core/styles';

interface PropTypes extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

interface StateTypes {
  hasError: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh'
    },
    text: {
      marginBottom: theme.spacing(2)
    }
  });

class ErrorBoundary extends React.Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  public static getDerivedStateFromError = (error: Error): StateTypes => ({
    hasError: true
  });

  public componentDidCatch(error: Error, info: { componentStack: string }) {
    // eslint-disable-next-line no-console
    console.warn(
      '%cError',
      'color: green; font-style: italic; background-color: blue; padding: 2px;',
      error,
      info.componentStack
    );
  }

  public render() {
    const { classes, children } = this.props;
    const { hasError } = this.state;

    if (!hasError) {
      return children;
    }

    return (
      <Box className={classes.root}>
        <Typography className={classes.text} variant="h2">
          Упс, щось пішло не так...
        </Typography>
        <Button variant="outlined" color="primary" href="/">
          Повернутись на головну
        </Button>
      </Box>
    );
  }
}

export default withStyles(styles)(ErrorBoundary);
