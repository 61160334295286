import { WebStorageStateStore } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import { CLIENT_ID, IDENTITY_SERVER } from 'src/config';

export const userManagerConfig = {
  authority: IDENTITY_SERVER,
  client_id: CLIENT_ID,
  client_secret: 'zi9wngu8r9u0',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/signin-callback`,
  response_type: 'code',
  scope: 'openid profile offline_access',
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/trip`,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  checkSessionInterval: 5000,
  silentRequestTimeout: 5000
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
