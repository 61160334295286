import { forwardRef, useEffect, useCallback } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import track from 'src/utils/analytics';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  title?: string;
  children?: React.ReactNode;
  rest?: object;
  className?: string;
};

const Page = forwardRef(
  (
    { title, children, ...rest }: ComponentProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const location = useLocation();
    const history = useHistory();
    const { i18n } = useTranslation();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: location.pathname
      });
    }, [location]);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const lang = queryParams.get('lang');
      if (lang) {
        i18n.changeLanguage(lang);
        queryParams.delete('lang');
        history.replace({
          search: queryParams.toString()
        });
      }
    }, [history, i18n, location.search]);

    return (
      <div ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.defaultProps = {
  title: '',
  children: <></>,
  rest: {},
  className: ''
};

export default Page;
