import { gql } from '@apollo/client';

export const MAX_MARKER_VISIBLE_ZOOM = 13;
export const MIN_GPS_ACCURACY = 500;
export const SNAP_GAP = 50;

export const GET_ROUTE_QUERY = gql`
  query Route($id: String!) {
    route(id: $id) {
      textColor
      shortName
      patterns {
        stops {
          gtfsId
          name
          lat
          lon
        }
        code
        directionId
        patternGeometry {
          points
        }
      }
    }
  }
`;
