import { all, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import TripSaga from 'src/views/TripView/store/sagas';
import MainLayoutSaga from 'src/layouts/MainLayout/store/sagas';
import AppSagas from './sagas/appSagas';
import MapLimitsSaga from './sagas/mapLimitsSaga';

function* rootSaga(): SagaIterator {
  yield all([
    call(AppSagas),
    call(MapLimitsSaga),
    call(TripSaga),
    call(MainLayoutSaga)
  ]);
}

export default rootSaga;
