import { Box, makeStyles } from '@material-ui/core';
import Lottie from 'lottie-react';
import loadingAnimation from 'src/assets/lottie/loader.json';

export const useStyles = makeStyles({
  whiteCircle: {
    position: 'absolute',
    height: 48,
    width: 48,
    borderRadius: '50%',
    backgroundColor: 'transparent'
  }
});

const Loader = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mx="auto"
      height={100}
      width={100}
      position="relative"
    >
      <Box className={classes.whiteCircle} />
      <Lottie animationData={loadingAnimation} />
    </Box>
  );
};

export default Loader;
