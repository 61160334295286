import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { LogOut, User } from 'react-feather';
import { selectIsAuthenticated } from 'src/store/selectors';
import { ArrowDropDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ACCOUNTS, ESHOP_URL } from 'src/config';
import { useRedirect } from 'src/hooks/useRedirect';
import { selectUser } from './store/selectors';
import { useAuth } from '../../hooks/useAuth';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  popover: {
    width: 250,
    borderRadius: 16
  },
  tab: {
    minHeight: '63px',
    minWidth: 0,
    textTransform: 'none'
  },
  tabWrapper: {
    [breakpoints.down('md')]: {
      alignItems: 'start'
    },
    display: 'block'
  },
  tabText: {
    fontSize: 14
  },
  menuItem: {
    padding: `${spacing(1.5)}px ${spacing(2.5)}px`,
    fontSize: 14
  },
  email: {
    marginTop: spacing(0.5)
  },
  logOutIcon: {
    marginRight: spacing(1)
  }
}));

function Account() {
  const classes = useStyles();
  const redirect = useRedirect(
    `loginUrl=${process.env.REACT_APP_API_BASE_URL!}/login`
  );
  const ref = useRef(null);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { name, email } = useSelector(selectUser);
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation('topBar');

  const { handleLogIn, handleLogout } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tab
        classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
        component="a"
        ref={ref}
        label={
          isDesktop ? (
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                color="textPrimary"
                className={classes.tabText}
              >
                {t('account')}
              </Typography>
              <ArrowDropDown height={24} width={24} />
            </Box>
          ) : (
            <User />
          )
        }
        onClick={handleOpen}
      />
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Box onClick={handleClose}>
          {isAuthenticated ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                className={classes.menuItem}
              >
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  className={classes.tabText}
                >
                  {name}
                </Typography>
                <Typography
                  className={classes.email}
                  variant="caption"
                  color="textSecondary"
                >
                  {email}
                </Typography>
              </Box>
              <Divider />
              <MenuItem
                className={classes.menuItem}
                component="a"
                href={redirect(`${ESHOP_URL}/overview/cards`)}
              >
                {t('myCards')}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component="a"
                href={redirect(`${ESHOP_URL}/settings`)}
              >
                {t('accountSettings')}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component={RouterLink}
                to="/trip-settings"
              >
                {t('tripSettings')}
              </MenuItem>
              <Divider />
              <MenuItem
                className={classes.menuItem}
                component="a"
                onClick={handleLogout}
              >
                <LogOut className={classes.logOutIcon} />
                {t('logOut')}
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                className={classes.menuItem}
                component={RouterLink}
                to="/trip-settings"
              >
                {t('tripSettings')}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component="a"
                onClick={handleLogIn}
              >
                {t('login')}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                component="a"
                href={redirect(`${ACCOUNTS}/account/register`)}
              >
                {t('signUp')}
              </MenuItem>
            </>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default Account;
