import { ActionType } from 'typesafe-actions';

const loadingReducers = (state = {}, action: ActionType<any>) => {
  const { type, config } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // ignore not a *_REQUEST / *_SUCCESS /  *_FAILURE actions
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  const key: string = config?.reducerName
    ? `${requestName}/${config.reducerName}`
    : requestName;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [key]: requestState === 'REQUEST'
  };
};

export default loadingReducers;
