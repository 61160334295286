import { RootState } from 'typesafe-actions';

export const selectHeaderLinks = (state: RootState) =>
  state.ui.mainLayout.headerMenuItems;

export const selectBasketItemsAmount = (state: RootState) =>
  state.ui.mainLayout.basketItemsAmount;

export const selectUser = (state: RootState) => {
  const { user } = state.oidc;

  return {
    name: user?.profile?.name ?? '',
    email: user?.profile.preferred_username ?? ''
  };
};

export const selectLoading = (state: RootState) => state.ui.mainLayout.loading;
