import * as React from 'react';
import { MapContainer, TileLayer, MapContainerProps } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { TILE_SERVER } from 'src/config';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tileLayer: {
    '& .leaflet-control-attribution': {
      position: 'absolute !important',
      bottom: -22
    }
  }
});

const Map: React.FC<MapContainerProps> = props => {
  const classes = useStyles();
  const { children, ...rest } = props;

  return (
    <MapContainer
      tap={false}
      keyboard={false}
      zoomControl={false}
      bounds={undefined}
      closePopupOnClick={false}
      {...rest}
    >
      <TileLayer
        className={classes.tileLayer}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={`${TILE_SERVER}/styles/basic/{z}/{x}/{y}.png`}
        maxZoom={18}
        minZoom={11}
      />
      {children}
    </MapContainer>
  );
};

export default Map;
