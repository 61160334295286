import { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  IconButton,
  SvgIcon,
  Drawer
} from '@material-ui/core';
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather';
import { useHistory } from 'react-router-dom';
import Nav from './Nav';

const useStyles = makeStyles(() => ({
  drawer: {
    width: '85vw',
    maxWidth: 325
  }
}));

const MobileNav = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setMobileNavOpen(false);
    });
  }, [history]);

  return (
    <>
      <IconButton color="inherit" onClick={() => setMobileNavOpen(true)}>
        <SvgIcon fontSize="small">
          <MenuIcon />
        </SvgIcon>
      </IconButton>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={() => setMobileNavOpen(false)}
        open={isMobileNavOpen}
        variant="temporary"
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Box mr={1.125}>
            <IconButton color="primary" onClick={() => setMobileNavOpen(false)}>
              <CloseIcon size={24} />
            </IconButton>
          </Box>
          <Box width="100%">
            <Nav />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNav;
