import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { getMapBounds } from 'src/utils/map';
import { find, get } from 'lodash';
import { FavoriteRouteType, UserAddressType, ViewModes } from '../types';

export const selectAutocomleteList = (state: RootState) =>
  state.ui.trip.autocomleteList;

export const selectSelectedRoute = (state: RootState) =>
  state.ui.trip.selectedRoute;

export const selectStartPoint = (state: RootState) => state.ui.trip.startPoint;

export const selectDestinationPoint = (state: RootState) =>
  state.ui.trip.destinationPoint;

export const selectRecents = (state: RootState) => state.ui.trip.recents;

export const selectPointOnMap = (state: RootState) =>
  state.ui.trip.selectedOnMapPoint;

export const selectLocationInfo = (state: RootState) =>
  state.ui.trip.locationInfo;

export const selectUserLocation = (state: RootState) =>
  state.ui.trip.userLocation;

export const selectRouteOption = (state: RootState) =>
  state.ui.trip.routeOption;

export const selectTimeSettings = (state: RootState) =>
  state.ui.trip.timeSettings;

export const selectBounds = createSelector(
  selectSelectedRoute,
  selectStartPoint,
  selectDestinationPoint,
  (
    selectedRoute: ItineraryType | null,
    from: SelectedPointType | null,
    to: SelectedPointType | null
  ) => getMapBounds(selectedRoute, from, to)
);

export const selectViewMode = (state: RootState) => state.ui.trip.viewMode;

export const selectUserAddresses = (
  state: RootState
): {
  Home?: SelectedPointType;
  Work?: SelectedPointType;
} => state.ui.trip.userAddresses;

export const selectActiveUserAddress = (
  state: RootState
): UserAddressType | null => state.ui.trip.activeUserAddress;

export const selectFavoritePlaces = (state: RootState) =>
  state.ui.trip.favoritePlaces;

export const selectFavoriteRoutes = (state: RootState) =>
  state.ui.trip.favoriteRoutes;

export const selectFavoriteRouteSelected = createSelector(
  selectFavoriteRoutes,
  selectStartPoint,
  selectDestinationPoint,
  (
    favoriteRoutes: FavoriteRouteType[],
    from: SelectedPointType | null,
    to: SelectedPointType | null
  ) =>
    find(
      favoriteRoutes,
      route =>
        route.from?.lat === from?.lat &&
        route.from?.lng === from?.lng &&
        route.to?.lat === to?.lat &&
        route.to?.lng === to?.lng
    )
);

export const selectIsRouteDetailsShown = createSelector(
  selectViewMode,
  (viewMode: ViewModes) => viewMode === ViewModes.ROUTE_DETAILS_VIEW
);

export const selectIsSearchShown = createSelector(
  selectViewMode,
  (viewMode: ViewModes) => viewMode === ViewModes.SEARCH_VIEW
);

export const selectIsStopDetailsShown = createSelector(
  selectViewMode,
  (viewMode: ViewModes) => viewMode === ViewModes.STOP_DETAILS_VIEW
);

export const selectIsUserAddressShown = createSelector(
  selectViewMode,
  (viewMode: ViewModes) => viewMode === ViewModes.USER_ADDRESS_VIEW
);

export const selectIsSuggestedRoutesShown = createSelector(
  selectStartPoint,
  selectDestinationPoint,
  (startPoint, destinationPoint) => startPoint && destinationPoint
);

export const selectIsFavoriteRoutesLoading = (state: RootState) =>
  get(state, `loading.@TRIP/GET_FAVORITE_ROUTES`);

export const selectIsSelectOnMapLoading = (state: RootState) =>
  get(state, `loading.@TRIP/SET_POINT_FROM_MAP_ON_MOBILE`);

export const selectIsHomeWorkLoading = (state: RootState) =>
  get(state, `loading.@TRIP/GET_USER_ADDRESSES`) ||
  get(state, `loading.@TRIP/ADD_USER_ADDRESS`) ||
  get(state, `loading.@TRIP/DELETE_USER_ADDRESS`);

export const selectStop = (state: RootState) => state.ui.trip.selectedStop;
