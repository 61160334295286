import { useEffect } from 'react';
import { Box, colors, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as BasketIcon } from 'src/assets/icons/header/shopping-bag.svg';
import { useDispatch, useSelector } from 'react-redux';
import userManager from 'src/utils/userManager';
import { selectIsAuthenticated } from 'src/store/selectors';
import { getCookie } from 'src/utils/cookie';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { ESHOP_URL } from '../../config';
import { useRedirect } from '../../hooks/useRedirect';

const cardIdCookieKey = 'cartSessionId';

const useStyles = makeStyles(({ palette }) => ({
  itemsNumberWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.background.default,
    width: 15,
    height: 15,
    borderRadius: '50%',
    top: 0,
    right: 0
  },
  itemsNumberInnerWrapper: {
    backgroundColor: colors.green.A700,
    width: 13,
    height: 13,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemsNumberText: {
    color: palette.common.white,
    fontSize: 9
  }
}));

const Basket = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const basketItemsAmount = useSelector(selectors.selectBasketItemsAmount);

  useEffect(() => {
    if (isAuthenticated) {
      userManager.getUser().then(data => {
        dispatch(actions.getBasketItems.request(data?.profile?.sub || ''));
      });
    } else {
      dispatch(
        actions.getBasketItems.request(getCookie(cardIdCookieKey) || '')
      );
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Box mr={2} ml={1.5} p={0.5} position="relative">
      <a href={redirect(`${ESHOP_URL}/shop/cart`)}>
        <BasketIcon />
        {!!basketItemsAmount && (
          <Box className={classes.itemsNumberWrapper}>
            <Box className={classes.itemsNumberInnerWrapper}>
              <Typography
                variant="overline"
                className={classes.itemsNumberText}
              >
                {basketItemsAmount}
              </Typography>
            </Box>
          </Box>
        )}
      </a>
    </Box>
  );
};

export default Basket;
