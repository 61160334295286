import { init as initApm } from '@elastic/apm-rum';
import { ELASTIC_APM } from './config';

// the Elastic APM RUM agent config
if (ELASTIC_APM) {
  const apm = initApm({
    serviceName: 'passenger-ui',
    serverUrl: ELASTIC_APM,
    serviceVersion: '0.1.0'
  });

  apm.setInitialPageLoadName('index');
}
