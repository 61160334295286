import { FC, ReactElement, ReactNode } from 'react';
import { ChevronDown } from 'react-feather';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  title: ReactNode;
  icon: ReactElement;
  isExtended: boolean;
  onClick: () => void;
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  title: {
    marginLeft: spacing(1.5)
  },
  arrow: {
    marginLeft: 'auto',
    color: palette.primary.light
  },
  expandedArrow: {
    transform: 'rotate(180deg)',
    transition: 'transform ease-in-out .3s'
  }
}));

const AccordionHead: FC<Props> = ({ title, icon, isExtended, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} onClick={onClick}>
      {icon}
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.title}
      >
        {title}
      </Typography>
      <ChevronDown
        className={clsx(classes.arrow, isExtended && classes.expandedArrow)}
      />
    </Box>
  );
};

export default AccordionHead;
