import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { Clock as ClockIcon } from 'react-feather';
import { DirectionsWalk as DirectionsWalkIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { secondsToMinutesAndHours, secondsToMinutes } from '../../utils';

interface PropsType {
  duration: number;
  walkTime: number;
}

const useStyles = makeStyles(({ palette, typography }) => ({
  including: {
    color: palette.primary.light,
    height: 16
  },
  timeText: {
    fontWeight: 500,
    color: palette.text.primary
  },
  walkIcon: {
    fontSize: 20,
    fill: palette.primary.light
  }
}));

const RouteDuration = ({ duration, walkTime }: PropsType) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { t } = useTranslation('trip');
  const { minutes, hours } = secondsToMinutesAndHours(duration);
  const walk = secondsToMinutes(walkTime);

  return (
    <Box mb={3} display="flex" alignItems="flex-start">
      <ClockIcon size={20} color={palette.primary.light} />
      <Box ml={1}>
        <Typography variant="body2" className={classes.timeText}>
          {`${hours ? `${hours} ${t('h')} ` : ''}${minutes} ${t('min')}`}
        </Typography>
      </Box>
      {walk > 0 && (
        <>
          <Box ml={1} mr={1}>
            <Typography variant="body2" className={classes.including}>{`${t(
              'including'
            )}`}</Typography>
          </Box>
          <DirectionsWalkIcon className={classes.walkIcon} />
          <Box ml={1}>
            <Typography variant="body2">{`${walk} ${t('min')}`}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default RouteDuration;
