import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ESHOP_API } from 'src/config';
import apiClient from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { getType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import { UserData } from '../types';

export function* getHeaderFooterLinks({
  payload
}: ReturnType<typeof actions.getHeaderFooterLinks.request>): SagaIterator {
  const { data } = yield call(
    apiClient.get,
    `${ESHOP_API}/StaticContent/HeaderFooterLinks`,
    {
      headers: {
        'Accept-Language': payload
      }
    }
  );

  yield put(actions.getHeaderFooterLinks.success(data));
}

export function* getBasketItems({
  payload
}: ReturnType<typeof actions.getBasketItems.request>): SagaIterator {
  if (payload) {
    const { data }: { data: { itemsCount: number } } = yield call(
      apiClient.get,
      `${ESHOP_API}/ShoppingCart/${payload}`
    );

    yield put(actions.getBasketItems.success(data?.itemsCount));
  }
}

export function* getUser() {
  const { data }: AxiosResponse<UserData> = yield call(
    apiClient.get,
    `${ESHOP_API}/User`
  );

  yield put(actions.getUser.success(data));
}

export default function* TripSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getHeaderFooterLinks.request),
      safe(getHeaderFooterLinks, actions.getHeaderFooterLinks.failure)
    ),
    takeEvery(
      getType(actions.getBasketItems.request),
      safe(getBasketItems, actions.getBasketItems.failure)
    ),
    takeEvery(
      getType(actions.getUser.request),
      safe(getUser, actions.getUser.failure)
    )
  ]);
}
