import { LatLngLiteral } from 'leaflet';
import { RouteOptions } from 'src/types/enums';
import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  AutocompletePointType,
  PointType,
  ActiveFieldType,
  TimeSettings,
  UserAddressType,
  FavoritePlaceType,
  POIErrorResponce,
  FavoriteRouteType,
  ViewModes
} from '../types';

export const setSelectedRoute = createAction(
  '@TRIP/SET_SELECTED_ROUTE',
  (payload: ItineraryType | null) => payload
)();

export const setPoint = createAction(
  '@TRIP/SET_POINT',
  (payload: { point: SelectedPointType | null; pointType: PointType }) =>
    payload
)();

export const setSelectOnMap = createAction(
  '@TRIP/SET_IS_SELECT_ON_MAP_VISIBLE',
  (payload: ActiveFieldType) => payload
)();

export const setRouteOption = createAction(
  '@TRIP/SET_ROUTE_OPTION',
  (payload: RouteOptions) => payload
)();

export const setTimeSetting = createAction(
  '@TRIP/SET_TIME_SETTINGS',
  (payload: TimeSettings) => payload
)();

export const reversePoints = createAction('@TRIP/REVERSE_POINTS')();

export const getAutocompleteData = createAsyncAction(
  '@TRIP/GET_AUTOCOMLETE_DATA_REQUEST',
  '@TRIP/GET_AUTOCOMLETE_DATA_SUCCESS',
  '@TRIP/GET_AUTOCOMLETE_DATA_FAILURE'
)<{ text: string; lang: string }, AutocompletePointType[], Error>();

export const setPointFromMap = createAsyncAction(
  '@TRIP/SET_POINT_FROM_MAP_REQUEST',
  '@TRIP/SET_POINT_FROM_MAP_SUCCESS',
  '@TRIP/SET_POINT_FROM_MAP_FAILURE'
)<
  { coordinates: LatLngLiteral; pointType: PointType; language: string },
  SelectedPointType,
  Error
>();

export const setUserLocationPoint = createAsyncAction(
  '@TRIP/SET_USER_LOCATION_POINT_REQUEST',
  '@TRIP/SET_USER_LOCATION_POINT_SUCCESS',
  '@TRIP/SET_USER_LOCATION_POINT_FAILURE'
)<
  { coordinates: LatLngLiteral; pointType: PointType; language: string },
  SelectedPointType,
  Error
>();

export const setIsSuggestedRouteDetailsShown = createAction(
  '@TRIP/SET_IS_SUGGESTED_ROUTE_DETAILS_SHOWN',
  (payload?: ItineraryType) => payload
)();

/** Places */
export const setPointFromMapOnMobile = createAsyncAction(
  '@TRIP/SET_POINT_FROM_MAP_ON_MOBILE_REQUEST',
  '@TRIP/SET_POINT_FROM_MAP_ON_MOBILE_SUCCESS',
  '@TRIP/SET_POINT_FROM_MAP_ON_MOBILE_FAILURE'
)<{ coordinates: LatLngLiteral; language: string }, SelectedPointType, Error>();

export const addUserAddressFromMap = createAsyncAction(
  '@TRIP/ADD_USER_ADDRESS_FROM_MAP_REQUEST',
  '@TRIP/ADD_USER_ADDRESS_FROM_MAP_SUCCESS',
  '@TRIP/ADD_USER_ADDRESS_FROM_MAP_FAILURE'
)<{ coordinates: LatLngLiteral; language: string }, SelectedPointType, Error>();

export const getFavoritePlaces = createAsyncAction(
  '@TRIP/GET_FAVORITE_PLACES_REQUEST',
  '@TRIP/GET_FAVORITE_PLACES_SUCCESS',
  '@TRIP/GET_FAVORITE_PLACES_FAILURE'
)<undefined, FavoritePlaceType[], Error>();

export const createFavoritePlace = createAsyncAction(
  '@TRIP/CREATE_FAVORITE_PLACE_REQUEST',
  '@TRIP/CREATE_FAVORITE_PLACE_SUCCESS',
  '@TRIP/CREATE_FAVORITE_PLACE_FAILURE'
)<SelectedPointType, FavoritePlaceType, POIErrorResponce[]>();

export const deleteFavoritePlace = createAsyncAction(
  '@TRIP/DELETE_FAVORITE_PLACE_REQUEST',
  '@TRIP/DELETE_FAVORITE_PLACE_SUCCESS',
  '@TRIP/DELETE_FAVORITE_PLACE_FAILURE'
)<number, number, Error>();

/** Routes */
export const getFavoriteRoutes = createAsyncAction(
  '@TRIP/GET_FAVORITE_ROUTES_REQUEST',
  '@TRIP/GET_FAVORITE_ROUTES_SUCCESS',
  '@TRIP/GET_FAVORITE_ROUTES_FAILURE'
)<undefined, FavoriteRouteType[], Error>();

export const createFavoriteRoute = createAsyncAction(
  '@TRIP/CREATE_FAVORITE_ROUTE_REQUEST',
  '@TRIP/CREATE_FAVORITE_ROUTE_SUCCESS',
  '@TRIP/CREATE_FAVORITE_ROUTE_FAILURE'
)<undefined, FavoriteRouteType, Error>();

export const deleteFavoriteRoute = createAsyncAction(
  '@TRIP/DELETE_FAVORITE_ROUTE_REQUEST',
  '@TRIP/DELETE_FAVORITE_ROUTE_SUCCESS',
  '@TRIP/DELETE_FAVORITE_ROUTE_FAILURE'
)<number, number, Error>();

/** User Address */
export const getUserAddresses = createAsyncAction(
  '@TRIP/GET_USER_ADDRESSES_REQUEST',
  '@TRIP/GET_USER_ADDRESSES_SUCCESS',
  '@TRIP/GET_USER_ADDRESSES_FAILURE'
)<
  undefined,
  {
    Home?: SelectedPointType;
    Work?: SelectedPointType;
  },
  Error
>();

export const setActiveUserAddress = createAction(
  '@TRIP/SET_ACTIVE_USER_ADDRESS',
  (payload: UserAddressType | null) => payload
)();

export const addUserAddress = createAsyncAction(
  '@TRIP/ADD_USER_ADDRESS_REQUEST',
  '@TRIP/ADD_USER_ADDRESS_SUCCESS',
  '@TRIP/ADD_USER_ADDRESS_FAILURE'
)<SelectedPointType, SelectedPointType, Error>();

export const deleteUserAddress = createAsyncAction(
  '@TRIP/DELETE_USER_ADDRESS_REQUEST',
  '@TRIP/DELETE_USER_ADDRESS_SUCCESS',
  '@TRIP/DELETE_USER_ADDRESS_FAILURE'
)<UserAddressType, UserAddressType, Error>();

export const resetTrip = createAction('@TRIP/RESET_TRIP')();

export const setViewMode = createAction(
  '@TRIP/SET_VIEW_MODE',
  (payload: ViewModes) => payload
)();

export const setSelectedStop = createAction(
  '@TRIP/SET_SELECTED_STOP',
  (payload: SelectedStopType) => payload
)();

export const resetPersistentData = createAction(
  '@TRIP/RESET_PERSISTENT_DATA'
)();

export const goBackUserAddresses = createAction(
  '@TRIP/GO_BACK_USER_ADDRESSES'
)();

export const setLocationInfo = createAction(
  '@TRIP/SET_LOCATION_INFO',
  (payload: SelectedPointType | null) => payload
)();

export const setUserLocation = createAction(
  '@TRIP/SET_USER_LOCATION',
  (payload: LatLngLiteral | null) => payload
)();
