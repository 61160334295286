import { merge } from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';
import { THEMES } from '../constants';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: '#2196f3'
        }
      },
      textColorInherit: {
        opacity: 1
      }
    },
    MuiInputBase: {
      input: {
        '@media only screen and (max-width: 600px)': {
          fontSize: 16
        }
      }
    }
  }
};

export const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: '#fafafa',
        paper: colors.common.white,
        dark: '#e5e5e5',
        light: '#546E7A14'
      },
      primary: {
        main: '#263238',
        dark: '#000A12',
        light: '#546E7A'
      },
      secondary: {
        main: '#304ffe',
        dark: '#304ffe',
        light: '#304ffe80'
      },
      success: {
        main: '#00c853',
        dark: '#2e7d32',
        light: '#00c85380'
      },
      warning: {
        main: '#F39021',
        light: '#fe9800'
      },
      error: {
        main: '#ff0066',
        light: '#ff006680'
      },
      text: {
        primary: '#263238',
        secondary: '#546e7a',
        hint: '#b0bec5',
        grey: '#d2d2d2'
      }
    },
    shadows: softShadows
  }
];

export function createTheme(settings: {
  theme: string;
  direction: string;
  responsiveFontSizes: boolean;
}) {
  let themeConfig = themeConfigs.find(item => item.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  const options = merge({}, baseConfig, themeConfig, {
    direction: settings.direction
  }) as Object;

  let theme = createMuiTheme(options);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
