import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    backgroundColor: palette.background.default
  },
  heading: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      whiteSpace: 'nowrap'
    }
  },
  headerWrapper: {
    [breakpoints.up('sm')]: {
      width: 326
    }
  },
  route: {
    cursor: 'pointer',
    borderRadius: 16,
    paddingBottom: spacing(4),
    padding: spacing(2),
    margin: `${spacing(1)}px 0`,
    border: 'solid 2px transparent',
    [breakpoints.up('sm')]: {
      '&$active': {
        border: `solid 2px ${palette.secondary.main}`
      },
      paddingBottom: 0
    }
  },
  active: {},
  including: {
    color: palette.grey[600],
    height: 14
  },
  divider: {
    position: 'relative',
    top: -40,
    height: 2,
    backgroundColor: palette.grey[300],
    marginLeft: spacing(1),
    marginRight: spacing(1)
  },
  detailsBtn: {
    width: 'calc(100% + 32px)',
    marginTop: spacing(4),
    marginLeft: -spacing(2),
    borderRadius: 0,
    borderTop: `1px solid ${palette.grey[200]}`,
    '&$active': {
      color: palette.secondary.main
    }
  },
  refreshWrapper: {
    marginLeft: 'auto'
  }
}));
