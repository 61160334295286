import { FC, ChangeEvent, useState } from 'react';
import {
  MenuItem,
  Select as SelectComponent,
  InputBase,
  makeStyles,
  withStyles,
  createStyles
} from '@material-ui/core';
import clsx from 'clsx';

type SelectItem = {
  text: string;
  value: string;
};

type Props = {
  id: string;
  data: SelectItem[];
  selectedValue: string;
  onChange: (e: ChangeEvent<{ value: unknown }>) => void;
};

const BootstrapInput = withStyles(({ palette }) =>
  createStyles({
    root: {
      width: '100%'
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      padding: '10px 15px 10px 12px',
      '&:focus': {
        borderRadius: 20,
        backgroundColor: palette.background.default
      }
    }
  })
)(InputBase);

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    whiteSpace: 'break-spaces'
  },
  selected: {
    backgroundColor: `${palette.common.white} !important`,
    fontWeight: 'bold'
  },
  icon: {
    color: palette.primary.main,
    marginRight: spacing(1)
  },
  select: {
    border: `1px solid ${palette.primary.light}`
  },
  activeSelect: {
    border: `1px solid ${palette.success.main}`
  },
  selectMenu: {
    borderRadius: 16
  }
}));

const Select: FC<Props> = ({ id, data, selectedValue, onChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <SelectComponent
      id={id}
      value={selectedValue}
      onChange={onChange}
      open={isOpen}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      input={<BootstrapInput />}
      classes={{
        select: clsx(classes.select, isOpen && classes.activeSelect),
        icon: classes.icon
      }}
      MenuProps={{
        classes: { paper: classes.selectMenu },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      }}
    >
      {data.map(({ text, value }) => (
        <MenuItem key={value} value={value} classes={classes}>
          {text}
        </MenuItem>
      ))}
    </SelectComponent>
  );
};

export default Select;
