export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const LANGS = {
  EN: 'en',
  UK: 'uk'
};

export const LANGS_LABELS = {
  uk: 'УКР',
  en: 'EN'
};

export const ITINERARY_LINE_TEXT_MAP = {
  BUS: '000003',
  TRAM: '000000',
  TROLLEYBUS: '000011'
};

export const ITINERARY_LINE_COLOR_MAP = {
  [ITINERARY_LINE_TEXT_MAP.BUS]: '#e57373',
  [ITINERARY_LINE_TEXT_MAP.TRAM]: '#26a69a',
  [ITINERARY_LINE_TEXT_MAP.TROLLEYBUS]: '#F39021'
} as { [key: string]: string };

export const ITINERARY_RETURN_LINE_COLOR_MAP = {
  [ITINERARY_LINE_TEXT_MAP.BUS]: '#b65c5c',
  [ITINERARY_LINE_TEXT_MAP.TRAM]: '#127970',
  [ITINERARY_LINE_TEXT_MAP.TROLLEYBUS]: '#c57600'
};

export const DEBOUNCE_DELAY = 500;
