export const ENV = process.env.REACT_APP_ENV;
export const API_BASE_URL = '/api';
export const TILE_SERVER = process.env.REACT_APP_TILE_SERVER;
export const IDENTITY_SERVER = process.env.REACT_APP_ACCOUNTS;
export const PELIAS_SERVER = process.env.REACT_APP_PELIAS;
export const AVL_SERVER = process.env.REACT_APP_AVL;
export const OPEN_TRIP_PLANNER = process.env.REACT_APP_OPEN_TRIP_PLANNER;
export const ELASTIC_APM = process.env.REACT_APP_ELASTIC_APM;
export const MQTT_BROKER = process.env.REACT_APP_MQTT_BROKER;
export const POI_SERVER = process.env.REACT_APP_POI;
export const ACCOUNTS = process.env.REACT_APP_ACCOUNTS;
export const ESHOP_URL = process.env.REACT_APP_ESHOP_URL;
export const ESHOP_API = process.env.REACT_APP_ESHOP_API;
export const CLIENT_ID = 'eshop3';
