import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';
import { TIME_FORMAT } from '../../const';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  startPoint: {
    zIndex: 1,
    display: 'flex',
    height: 16,
    width: 16,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${palette.grey[200]}`,
    backgroundColor: 'white'
  },
  startPointCenter: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: palette.success.main
  },
  startTime: {
    position: 'absolute',
    top: 26,
    left: -10
  }
}));

const StartPoint = ({ startTime }: { startTime: number }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.startPoint}>
        <div className={classes.startPointCenter} />
      </div>
      <Typography variant="body1" className={classes.startTime}>
        {moment(startTime).format(TIME_FORMAT)}
      </Typography>
    </>
  );
};

export default StartPoint;
