import moment from 'moment';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as DestinationIcon } from 'src/assets/icons/destination-point.svg';
import { TIME_FORMAT } from '../../const';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  destinationPoint: {
    zIndex: 1,
    height: 16,
    width: 16,
    background: 'white',
    color: palette.secondary.main
  },
  endTime: {
    position: 'absolute',
    top: 26,
    right: -10
  }
}));

const DestinationPoint = ({ endTime }: { endTime: number }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <DestinationIcon className={classes.destinationPoint} />
      <Typography variant="body1" className={classes.endTime}>
        {moment(endTime).format(TIME_FORMAT)}
      </Typography>
    </Box>
  );
};

export default DestinationPoint;
