import { createAction } from 'typesafe-actions';
import { SnackbarKey } from 'notistack';

export const enqueueSnackbar = createAction(
  '@SNACKBAR/ENQUEUE',
  (notification: UINotification) => ({
    ...notification,
    key:
      notification.key ||
      Math.random()
        .toString(16)
        .substring(2, 15)
  })
)();

export const removeSnackbar = createAction(
  '@SNACKBAR/REMOVE',
  (key: SnackbarKey) => ({ key })
)();
