import { createReducer, ActionType } from 'typesafe-actions';
import { Column, UserData } from '../types';

import * as actions from './actions';

const headerId = 'header';

type ActionsTypes = ActionType<typeof actions>;

export type StateTypes = Readonly<{
  headerMenuItems: Column | null;
  basketItemsAmount: number;
  user: UserData | null;
  loading: boolean;
}>;

export const initialState: StateTypes = {
  headerMenuItems: null,
  user: null,
  basketItemsAmount: 0,
  loading: false
};

const reducers = createReducer<StateTypes, ActionsTypes>(initialState)
  .handleAction(actions.getHeaderFooterLinks.request, (state, { payload }) => ({
    ...state,
    loading: true
  }))
  .handleAction(actions.getHeaderFooterLinks.success, (state, { payload }) => ({
    ...state,
    loading: false,
    headerMenuItems: payload.data.find(({ id }) => id === headerId)
      ?.columns[0] as Column
  }))
  .handleAction(actions.getBasketItems.success, (state, { payload }) => ({
    ...state,
    basketItemsAmount: payload
  }))
  .handleAction(actions.getUser.success, (state, { payload }) => ({
    ...state,
    user: payload
  }));

export default reducers;
