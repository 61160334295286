import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadUser } from 'redux-oidc';
import * as actions from 'src/layouts/MainLayout/store/actions';
import { resetPersistentData } from '../views/TripView/store/actions';
import { resetSettings } from '../views/TripSettingsView/store/actions';
import userManager from '../utils/userManager';
import { selectIsAuthenticated } from '../store/selectors';
import { ACCOUNTS, CLIENT_ID } from '../config';
import store from '../store';

const getUser = () => {
  const userJson = localStorage.getItem(`oidc.user:${ACCOUNTS}:${CLIENT_ID}`);

  return userJson ? JSON.parse(userJson) : null;
};

const initialUser = getUser();

export function useAuth() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('topBar');
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLogout = async () => {
    dispatch(resetPersistentData());
    dispatch(resetSettings());
    userManager.signoutRedirect();
  };

  const handleLogIn = () => {
    // @ts-ignore
    userManager.storeUser(null);
    userManager.clearStaleState();
    userManager.signinRedirect({
      ui_locales: i18n.language,
      extraQueryParams: {
        lang: i18n.language
      }
    });
  };

  const loadEnd = () => setIsLoaded(true);

  const silentLogin = useCallback(
    () =>
      userManager
        .signinSilent()
        .then(loadEnd)
        .catch(loadEnd),
    []
  );

  useEffect(() => {
    if (initialUser && !isAuthenticated) {
      // @ts-ignore
      userManager.storeUser(null);
      userManager.clearStaleState();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadUser(store, userManager);

      if (isLoaded) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isLoaded]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoaded(false);
      silentLogin();
    } else {
      setIsLoaded(true);
      dispatch(actions.getUser.request());
    }
  }, [dispatch, isAuthenticated, silentLogin]);

  return {
    silentLogin,
    setIsLoaded,
    handleLogIn,
    handleLogout
  };
}
