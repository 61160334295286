function track(...args: (string | object | undefined)[]) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!(window as any).gtag) {
    return;
  }

  (window as any).gtag(...args);
}

function pageview(props: object | undefined) {
  track('config', process.env.REACT_APP_GA_MEASUREMENT_ID, props);
}

function event(type: string | undefined, props: string | undefined) {
  track('event', type, props);
}

const analitycs = {
  pageview,
  event
};

export default analitycs;
