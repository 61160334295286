import { useSelector } from 'react-redux';
import Endpoint from 'src/components/Map/Endpoint';
import { ReactComponent as HomePin } from 'src/assets/icons/home-pin.svg';
import { ReactComponent as WorkPin } from 'src/assets/icons/work-pin.svg';
import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import * as selectors from '../store/selectors';
import { UserAddressType } from '../types';

function HomeWorkPin() {
  const map = useMap();
  const location = useSelector(selectors.selectLocationInfo);
  const activeUserAddress = useSelector(selectors.selectActiveUserAddress);

  useEffect(() => {
    location && map.flyTo([location?.lat, location?.lng]);
  }, [location, map]);

  return (
    <Endpoint
      iconAnchor={[20, 20]}
      location={location}
      MapMarkerIcon={
        activeUserAddress === UserAddressType.HOME ? HomePin : WorkPin
      }
    />
  );
}

export default HomeWorkPin;
