import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Box, Toolbar, makeStyles, Hidden } from '@material-ui/core';
import { ReactComponent as Logo } from 'src/assets/icons/header/logo-leocard.svg';
import Nav from './Nav';
import Account from './Account';
import Language from './Language';
import MobileNav from './MobileNav';
import Basket from './Basket';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    backgroundColor: palette.common.white,
    color: '#263238',
    boxShadow: '0 0 10px 0 rgba(1, 9, 50, 0.15)',
    position: 'fixed',
    top: 0
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('md')]: {
      justifyContent: 'flex-end',
      width: '100%'
    }
  },
  toolbar: {
    height: 64,
    display: 'flex',
    [breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(0.5),
    width: 100,
    [breakpoints.up('md')]: {
      position: 'absolute',
      marginLeft: 0,
      transform: 'translate(-50%, 0)',
      left: '50%',
      top: 0,
      height: '100%'
    }
  },
  logo: {
    width: '100%',
    height: '100%'
  }
}));

function TopBar({ className = null, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <MobileNav />
        </Hidden>
        <Hidden mdDown>
          <Nav />
        </Hidden>
        <RouterLink to="/">
          <Box className={classes.logoWrapper}>
            <Logo className={classes.logo} />
          </Box>
        </RouterLink>
        <Box className={classes.account}>
          <Language />
          <Box ml={0.5}>
            <Account />
          </Box>
          <Basket />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
