import { makeStyles } from '@material-ui/core';
import { ZoomControl as Zoom } from 'react-leaflet';

const useStyles = makeStyles(({ shadows, palette }) => ({
  '@global': {
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar.leaflet-control-zoom': {
      border: 'none',
      borderRadius: '50%',
      width: 38,
      color: palette.text.secondary,
      boxShadow: shadows[4]
    },
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar.leaflet-control-zoom .leaflet-control-zoom-in': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: '50%',
      borderTopLeftRadius: '50%',
      color: palette.text.secondary,
      minWidth: 38,
      minHeight: 40
    },
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar.leaflet-control-zoom .leaflet-control-zoom-out': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomRightRadius: '50%',
      borderBottomLeftRadius: '50%',
      color: palette.text.secondary,
      minWidth: 38,
      minHeight: 40
    }
  }
}));

function ZoomControl() {
  useStyles();
  return <Zoom position="bottomright" />;
}

export default ZoomControl;
