import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  MouseEvent,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  Ref,
  memo
} from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
  colors,
  InputBase,
  InputAdornment,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { X as CloseIcon } from 'react-feather';
import { DEBOUNCE_DELAY } from 'src/constants';
import { PointType } from '../types';
import { SEARCH_SYMBOLS } from '../const';
import * as actions from '../store/actions';
import SuggestedPoints from './SuggestedPoints';

interface Props {
  autoFocus?: boolean;
  type: PointType;
  value: string;
  placeholder: string;
  handleActive?: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<string>>;
  setPoint: (route: SelectedPointType) => void;
  onClose?: () => void;
}

export const useStyles = makeStyles(({ palette }) => ({
  input: {
    color: palette.common.black,
    '& ::-webkit-input-placeholder': {
      color: palette.text.secondary,
      opacity: 0.5
    }
  },
  cross: {
    display: 'flex',
    color: palette.common.black,
    cursor: 'pointer'
  },
  activeField: {
    display: 'flex'
  }
}));

const SearchField = forwardRef(
  (
    {
      autoFocus,
      type,
      placeholder,
      onClose,
      value,
      setValue,
      setPoint,
      handleActive
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { i18n } = useTranslation('trip');
    const [isActive, setIsActive] = useState(false);
    const [debouncedValue] = useDebounce(value, DEBOUNCE_DELAY);

    const fetchPoints = useCallback(
      (text: string) =>
        dispatch(
          actions.getAutocompleteData.request({
            text,
            lang: i18n.language
          })
        ),
      [dispatch, i18n.language]
    );

    const resetField = useCallback(() => {
      setValue('');
      dispatch(
        actions.setPoint({
          point: null,
          pointType: type
        })
      );
    }, [type, setValue, dispatch]);

    const handleFocus = () => {
      handleActive && handleActive(true);
      setIsActive(true);
    };

    const handleBlur = () => {
      handleActive && handleActive(false);
      setIsActive(false);
    };

    const handleValue = ({ target }: ChangeEvent<HTMLInputElement>) =>
      setValue(target.value);

    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      resetField();
      onClose && onClose();
    };

    useEffect(() => {
      if (isActive && debouncedValue.length > SEARCH_SYMBOLS) {
        fetchPoints(debouncedValue);
      }
    }, [isActive, debouncedValue, fetchPoints]);

    useEffect(() => {
      if (!value) resetField();
    }, [resetField, value]);

    return (
      <>
        <InputBase
          autoFocus={autoFocus}
          inputRef={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={handleValue}
          className={classes.input}
          fullWidth
          placeholder={placeholder}
          endAdornment={
            isActive &&
            value && (
              <InputAdornment position="end" className={classes.cross}>
                <IconButton onMouseDown={handleClose} color="primary">
                  <CloseIcon size={20} color={colors.common.black} />
                </IconButton>
              </InputAdornment>
            )
          }
        />
        <SuggestedPoints
          isActive={isActive}
          type={type}
          separator={value}
          setValue={setValue}
          setPoint={setPoint}
        />
      </>
    );
  }
);

export default memo(SearchField);
