import { useEffect, useState } from 'react';
import { DEBOUNCE_DELAY } from 'src/constants';

export default function useLoadingDebounce(loadingValue: boolean) {
  const [isLoading, setIsLoading] = useState(loadingValue);

  useEffect(() => {
    if (loadingValue) {
      setIsLoading(loadingValue);
    } else {
      setTimeout(() => setIsLoading(loadingValue), DEBOUNCE_DELAY);
    }
  }, [loadingValue]);

  return isLoading;
}
