import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface PropsType {
  transfers: number;
  walk: number;
}

const useStyles = makeStyles(({ palette }) => ({
  dot: {
    height: 32,
    borderRadius: 15,
    backgroundColor: palette.grey[300],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    position: 'relative'
  },
  value: {
    display: 'inline',
    fontWeight: 'bold'
  }
}));

const CollapsedLegs = ({ transfers, walk }: PropsType) => {
  const { t } = useTranslation('trip');
  const classes = useStyles();

  if (!transfers && !walk) {
    return null;
  }

  return (
    <Box px={2} className={classes.dot}>
      <Typography variant="body2">
        <span className={classes.value}>{transfers}</span>
        {` ${t('transfers')}`}
      </Typography>
      <Typography variant="body2">
        <span className={classes.value}>{walk}</span>
        {` ${t('minWalking')}`}
      </Typography>
    </Box>
  );
};

export default CollapsedLegs;
