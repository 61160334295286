import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { toLower } from 'lodash';
import { LANGS } from 'src/constants';

const cyrillicToTranslit = new CyrillicToTranslit({ preset: 'uk' });

const abbrMap: Record<string, string> = {
  вулиця: 'street',
  проспект: 'avenue',
  станція: 'station',
  площа: 'square',
  місто: 'city',
  'селище міського типу': 'urban-type settlement',
  село: 'village'
};
export const makeTransliteration = (lang: string) => (name: string) => {
  if (lang === LANGS.UK) {
    return name;
  }

  const reg = new RegExp(Object.keys(abbrMap).join('|'), 'gi');
  const newName = name?.replace(reg, matched => abbrMap[toLower(matched)]);

  return cyrillicToTranslit.transform(newName);
};
