import { Link, useLocation } from 'react-router-dom';
import { Location } from 'history';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Box,
  colors,
  Hidden,
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { useRedirect } from 'src/hooks/useRedirect';
import { ESHOP_URL } from 'src/config';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import Loader from '../../components/Loader';

export function getFirstPathPart(location: Location, defaultPath: string = '') {
  const pathFirstElement = location.pathname.match(/^\/[^/]+/);

  return pathFirstElement && pathFirstElement.length
    ? pathFirstElement[0].substring(1)
    : defaultPath;
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  indicator: {
    left: 0,
    justifyContent: 'center'
  },
  tab: {
    minHeight: '63px',
    marginRight: spacing(1.5),
    minWidth: 0,
    marginLeft: spacing(1.5),
    textTransform: 'none',
    [breakpoints.down('md')]: {
      paddingLeft: spacing(3.5)
    }
  },
  tabWrapper: {
    display: 'block'
  },
  tabText: {
    color: colors.blueGrey[900],
    fontSize: 14,
    width: 'fit-content'
  },
  popover: {
    borderRadius: 16
  },
  link: {
    textDecoration: 'none'
  },
  menuItem: {
    padding: `${spacing(1.5)}px ${spacing(2.5)}px`,
    fontSize: 14
  },
  aboutWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  activeText: {
    color: palette.secondary.dark
  },
  activeTab: {
    left: 0,
    right: 0,
    backgroundColor: palette.secondary.dark,
    [breakpoints.down('sm')]: {
      maxWidth: 2,
      maxHeight: 63
    }
  },
  item: {
    display: 'block',
    width: 'auto',
    marginLeft: spacing(4),
    marginRight: spacing(1.5)
  },
  button: {
    color: palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'space-between',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  }
}));

const Nav = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const theme = useTheme();
  const dispatch = useDispatch();
  const headerLinks = useSelector(selectors.selectHeaderLinks);
  const loading = useSelector(selectors.selectLoading);
  const ref = useRef(null);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation('topBar');
  const location = useLocation();
  const currentTab = getFirstPathPart(location, 'trip');

  const tougleOpen = () => setOpen(!isOpen);

  useEffect(() => {
    dispatch(actions.getHeaderFooterLinks.request(i18n.language));
  }, [dispatch, i18n.language]);

  const loader = (
    <Box
      display="flex"
      height={100}
      width={200}
      alignItems="center"
      justifyContent="center"
    >
      <Loader />
    </Box>
  );

  const eshopLinks = !loading
    ? headerLinks?.links.map(({ title, url, openAs }) => (
        <MenuItem
          key={title}
          className={classes.menuItem}
          target={openAs}
          component="a"
          href={redirect(`${ESHOP_URL}${url || '/'}`)}
        >
          <span
            style={{
              whiteSpace: 'break-spaces'
            }}
          >
            {title}
          </span>
        </MenuItem>
      ))
    : loader;

  const mobileLinks = (
    <ListItem className={classes.item} disableGutters>
      <Button className={classes.button} onClick={tougleOpen}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.tabText}
        >
          {t('about')}
        </Typography>
        <ArrowDropDown style={{ fill: colors.blueGrey[900] }} />
      </Button>
      <Collapse in={isOpen}>{eshopLinks}</Collapse>
    </ListItem>
  );

  return (
    <>
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={currentTab}
        variant="scrollable"
        TabIndicatorProps={{
          children: <span />,
          className: classes.activeTab
        }}
        orientation={matches ? 'vertical' : 'horizontal'}
      >
        <Tab
          value="trip"
          classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          label={
            <Typography
              variant="subtitle2"
              color="textPrimary"
              className={clsx(
                classes.tabText,
                currentTab === 'trip' && classes.activeText
              )}
            >
              {t('trip')}
            </Typography>
          }
          to="/trip"
          component={Link}
        />
        <Tab
          classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
          component="a"
          label={
            <Typography
              variant="subtitle2"
              color="textPrimary"
              className={classes.tabText}
            >
              {t('tickets')}
            </Typography>
          }
          href={redirect(ESHOP_URL)}
        />
        {!matches && (
          <Tab
            classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
            component="a"
            onClick={tougleOpen}
            ref={ref}
            label={
              <Box className={classes.aboutWrapper}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  className={classes.tabText}
                >
                  {t('about')}
                </Typography>
                <Hidden lgUp>
                  <ArrowDropDown />
                </Hidden>
              </Box>
            }
          />
        )}
      </Tabs>
      {matches ? (
        mobileLinks
      ) : (
        <Menu
          onClose={tougleOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          <Box onClick={tougleOpen}>{eshopLinks}</Box>
        </Menu>
      )}
    </>
  );
};

export default Nav;
