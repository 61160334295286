import { createSelector } from 'reselect';
import { selectIsFavoritesShown } from 'src/views/TripSettingsView/store/selectors';
import { RootState } from 'typesafe-actions';

export const selectSnackbar = (state: RootState) => state.snackbar;

export const selectMapLimits = (state: RootState) => state.mapLimits;

export const selectIsAuthenticated = (state: RootState) => !!state.oidc.user;

export const selectUser = (state: RootState) => state.oidc.user;

export const selectIsStarShown = createSelector(
  selectIsAuthenticated,
  selectIsFavoritesShown,
  (IsAuthenticated: boolean, IsFavoritesShown: boolean) =>
    IsAuthenticated && IsFavoritesShown
);
