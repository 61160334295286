import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import { themeConfigs } from 'src/theme';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, palette }) => ({
  dateInput: {
    minHeight: 40,
    border: `1px solid ${palette.primary.light}`,
    borderRadius: 20,
    paddingLeft: spacing(1.5)
  },
  icon: {
    padding: spacing(0.5)
  },
  helperText: {
    display: 'none'
  },
  helperTextError: {
    display: 'block'
  },
  activeinput: {
    borderColor: palette.success.main
  },
  positionEnd: {
    marginLeft: 0
  }
}));

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: themeConfigs[0].palette.success.main,
        color: themeConfigs[0].palette.background.paper
      },
      current: {
        color: themeConfigs[0].palette.primary.main
      }
    },
    MuiPaper: {
      root: {
        marginTop: 8
      },
      rounded: {
        borderRadius: 16
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: themeConfigs[0].palette.text.secondary
      },
      transitionContainer: {
        fontWeight: '500 !important'
      }
    },
    MuiTypography: {
      body1: {
        fontWeight: 500
      }
    }
  },
  palette: {
    primary: {
      main: themeConfigs[0].palette.success.light
    }
  }
});

const DatePicker = (props: KeyboardDatePickerProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const toggleOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return (
    <ThemeProvider theme={datePickerTheme}>
      <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
        <KeyboardDatePicker
          open={isOpen}
          onOpen={toggleOpen}
          onClose={toggleOpen}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
              error: classes.helperTextError
            }
          }}
          KeyboardButtonProps={{ classes: { root: classes.icon } }}
          InputAdornmentProps={{
            classes: {
              positionEnd: classes.positionEnd
            }
          }}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: clsx(classes.dateInput, isOpen && classes.activeinput)
            }
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
