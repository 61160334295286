import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import userManager from '../utils/userManager';

const Login = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // @ts-ignore
    userManager.storeUser(null);
    userManager.clearStaleState();
    userManager.signinRedirect({
      ui_locales: i18n.language,
      extraQueryParams: {
        lang: i18n.language
      }
    });
  });

  return null;
};

export default Login;
