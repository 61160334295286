import { createAsyncAction } from 'typesafe-actions';
import { GetHeaderFooterLinksResponce, UserData } from '../types';

export const getHeaderFooterLinks = createAsyncAction(
  '@MAIN_LAYOUT/GET_HEADER_FOOTER_LINKS_REQUEST',
  '@MAIN_LAYOUT/GET_HEADER_FOOTER_LINKS_SUCCESS',
  '@MAIN_LAYOUT/GET_HEADER_FOOTER_LINKS_FAILURE'
)<string, GetHeaderFooterLinksResponce, Error>();

export const getBasketItems = createAsyncAction(
  '@MAIN_LAYOUT/GET_BASKET_ITEMS_REQUEST',
  '@MAIN_LAYOUT/GET_BASKET_ITEMS_SUCCESS',
  '@MAIN_LAYOUT/GET_BASKET_ITEMS_FAILURE'
)<string, number, Error>();

export const getUser = createAsyncAction(
  '@MAIN_LAYOUT/GET_USER_REQUEST',
  '@MAIN_LAYOUT/GET_USER_SUCCESS',
  '@MAIN_LAYOUT/GET_USER_FAILURE'
)<undefined, UserData, Error>();
