import { Suspense, useEffect, lazy } from 'react';
import ZoomControl from 'src/components/Map/ZoomControl';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import Map from 'src/components/Map';
import { selectIsAuthenticated } from 'src/store/selectors';
import * as maplimitsActions from 'src/store/actions/mapLimits';
import LocateControl from 'src/components/Map/LocateControl';
import Endpoints from 'src/views/TripView/Map/Endpoints';
import {
  selectGlobalRouteOptions,
  selectIsFavoritesShown
} from 'src/views/TripSettingsView/store/selectors';
import { makeTransliteration } from 'src/utils/transliteration';
import SearchBar from './SearchBar';
import * as selectors from './store/selectors';
import * as actions from './store/actions';
import HomeWorkPin from './Map/HomeWorkPin';
import { MAP_CENTER } from './const';

const Itinerary = lazy(() => import('./Map/Itinerary'));
const ActiveStop = lazy(() => import('src/components/Map/ActiveStop'));
const SelectOnMap = lazy(() => import('./Map/SelectOnMap'));

const useStyles = makeStyles(({ spacing }) => ({
  '@global': {
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bottom.leaflet-right': {
      marginBottom: spacing(3),
      marginRight: spacing(3),
      width: 177
    },
    '.leaflet-control-attribution': {
      position: 'absolute',
      bottom: -22,
      width: 225
    }
  },
  page: {
    height: '100%',
    position: 'relative'
  }
}));

function TripView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSelectOnMapVissible = useSelector(selectors.selectPointOnMap);
  const bounds = useSelector(selectors.selectBounds);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const startPoint = useSelector(selectors.selectStartPoint);
  const isFavoritesShown = useSelector(selectIsFavoritesShown);
  const isUserAddressShown = useSelector(selectors.selectIsUserAddressShown);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const selectedRoute = useSelector(selectors.selectSelectedRoute);
  const isStopDetailsShown = useSelector(selectors.selectIsStopDetailsShown);
  const selectedStop = useSelector(selectors.selectStop) as SelectedStopType;
  const globalRouteOptions = useSelector(selectGlobalRouteOptions);

  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const { i18n, t } = useTranslation(); // prevents loading component before translations are loaded
  const transliterate = makeTransliteration(i18n.language);

  useEffect(() => {
    dispatch(maplimitsActions.getMapLimits.request());
    dispatch(actions.setRouteOption(globalRouteOptions));
    if (isAuthenticated) {
      if (isFavoritesShown) {
        dispatch(actions.getFavoritePlaces.request());
        dispatch(actions.getFavoriteRoutes.request());
      }
      dispatch(actions.getUserAddresses.request());
    }
    document.body.style.overscrollBehaviorY = 'contain'; // prevents Pull-to-Refresh on Mobile Browsers
    return () => {
      dispatch(actions.resetTrip());
    };
  }, [dispatch, globalRouteOptions, isAuthenticated, isFavoritesShown]);

  useEffect(() => {
    if (isDesktopScreen) {
      dispatch(actions.setSelectOnMap(null));
    }
  }, [dispatch, isDesktopScreen]);

  return (
    <Page className={classes.page} title={t('tripTitle')}>
      {!isSelectOnMapVissible && <SearchBar />}
      <Map
        center={MAP_CENTER}
        zoom={13}
        maxZoom={18}
        minZoom={11}
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        {isDesktopScreen && <ZoomControl />}
        <LocateControl />
        <Suspense fallback={() => null}>
          {!isStopDetailsShown && (
            <>
              {selectedRoute && startPoint && destinationPoint && (
                <Itinerary
                  bounds={bounds}
                  from={startPoint}
                  to={destinationPoint}
                  selectedRoute={selectedRoute}
                />
              )}
              <Endpoints />
            </>
          )}
          {isStopDetailsShown && (
            <ActiveStop
              {...selectedStop}
              name={transliterate(selectedStop.name)}
            />
          )}
          {!isDesktopScreen && isSelectOnMapVissible && <SelectOnMap />}
          {isUserAddressShown && isAuthenticated && <HomeWorkPin />}
        </Suspense>
      </Map>
    </Page>
  );
}

export default TripView;
