import { createAction } from 'typesafe-actions';

interface UrlParam {
  url: string;
}

export const navigateTo = createAction(
  '@APP/NAVIGATE_TO',
  (payload: UrlParam) => payload
)();
