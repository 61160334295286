import {
  Box,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { v4 as uuidv } from 'uuid';
import { themeConfigs } from 'src/theme';
import moment from 'moment';
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps
} from '@material-ui/pickers';
import clsx from 'clsx';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { FC, useRef, useState } from 'react';

type Props = {
  wrapperClasses: string;
  onTimePickerChange: (value: number, picker: 'minute' | 'hour') => void;
} & KeyboardTimePickerProps;

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersClockNumber: {
      clockNumberSelected: {
        color: themeConfigs[0].palette.background.paper
      }
    },
    MuiPaper: {
      root: {
        marginTop: 8
      },
      rounded: {
        borderRadius: 16
      }
    }
  },
  palette: {
    primary: {
      main: themeConfigs[0].palette.success.main
    }
  }
});

const useStyles = makeStyles(({ spacing, palette, shadows }) => ({
  clock: {
    visibility: 'hidden'
  },
  dateInput: {
    minHeight: 40,
    border: `1px solid ${palette.primary.light}`,
    borderRadius: 20,
    paddingLeft: spacing(1.5)
  },
  icon: {
    padding: spacing(0.5)
  },
  helperText: {
    display: 'none'
  },
  helperTextError: {
    display: 'block'
  },
  activeinput: {
    borderColor: palette.success.main
  },
  dropdownWrapper: {
    position: 'absolute',
    boxShadow: shadows[3],
    display: 'flex',
    flexDirection: 'row',
    marginTop: spacing(0.5),
    maxHeight: 300,
    width: 130,
    borderRadius: 16,
    backgroundColor: palette.common.white,
    zIndex: 1500,
    '@media (max-height: 676px)': {
      maxHeight: 150
    }
  },
  numbersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto'
  },
  number: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
    paddingTop: spacing(0.5),
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.background.default,
      '&:first-child': {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16
      },
      '&:last-child': {
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16
      }
    }
  },
  divider: {
    flex: 1,
    maxWidth: 1,
    backgroundColor: palette.background.dark
  },
  leftNumbersWrapper: {}
}));

const TimePicker: FC<Props> = ({ onTimePickerChange, ...props }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  useOnClickOutside(ref, close);

  return (
    <ThemeProvider theme={datePickerTheme}>
      <Box position="relative" className={props.wrapperClasses}>
        <KeyboardTimePicker
          open={false}
          onOpen={open}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
              error: classes.helperTextError
            }
          }}
          KeyboardButtonProps={{ classes: { root: classes.icon } }}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: clsx(classes.dateInput, isOpen && classes.activeinput)
            }
          }}
          PopoverProps={{
            classes: {
              paper: classes.clock
            }
          }}
          {...props}
        />
        {isOpen && (
          <div className={classes.dropdownWrapper} ref={ref}>
            <Box className={classes.numbersWrapper}>
              {new Array(24).fill(1).map((_, i) => (
                <Typography
                  key={uuidv()}
                  variant="body2"
                  className={classes.number}
                  onClick={() => {
                    onTimePickerChange(i, 'hour');
                  }}
                >
                  {moment(i, 'HH').format('HH')}
                </Typography>
              ))}
            </Box>
            <Box className={classes.divider} />
            <Box className={classes.numbersWrapper}>
              {new Array(12).fill(1).map((_, i) => {
                const minute = (i + 1) * 5 - 5;
                return (
                  <Typography
                    key={uuidv()}
                    variant="body2"
                    className={classes.number}
                    onClick={() => onTimePickerChange(minute, 'minute')}
                  >
                    {`${i < 2 ? '0' : ''}${minute}`}
                  </Typography>
                );
              })}
            </Box>
          </div>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default TimePicker;
