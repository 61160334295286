import { FC } from 'react';
import { ReactComponent as Alert } from 'src/assets/icons/snackbar/icons-feather-alert-triangle.svg';
import { ReactComponent as Check } from 'src/assets/icons/snackbar/icons-feather-check.svg';
import { ReactComponent as Info } from 'src/assets/icons/snackbar/material-info-black.svg';
import { Trash2 } from 'react-feather';
import { ReactComponent as LocationOff } from 'src/assets/icons/snackbar/location-off.svg';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    width: '100%',
    [breakpoints.up('sm')]: {
      maxWidth: 396,
      marginTop: spacing(8)
    }
  },
  variantError: {
    backgroundColor: palette.primary.dark
  },
  variantInfo: {
    backgroundColor: palette.primary.main
  },
  variantSuccess: {
    backgroundColor: palette.primary.main
  },
  icon: {
    marginRight: spacing(2),
    minWidth: 24,
    minHeight: 24,
    alignSelf: 'flex-start'
  },
  message: {
    maxWidth: '65vw',
    [breakpoints.up('sm')]: {
      maxWidth: 300
    }
  },
  action: {
    maxHeight: 24,
    alignSelf: 'flex-start',
    marginTop: spacing(1)
  },
  contentWrapper: {
    borderRadius: 16
  }
}));

export const NotistackProvider: FC = ({ children }) => {
  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const { palette } = useTheme();
  const classes = useStyles();

  return (
    <SnackbarProvider
      iconVariant={{
        default: <LocationOff className={classes.icon} />,
        success: <Check className={classes.icon} />,
        error: <Alert className={classes.icon} />,
        warning: <Trash2 className={classes.icon} color={palette.error.main} />,
        info: <Info className={classes.icon} />
      }}
      maxSnack={1}
      anchorOrigin={{
        vertical: isDesktopScreen ? 'top' : 'bottom',
        horizontal: 'right'
      }}
      classes={{
        containerRoot: classes.root,
        variantError: classes.variantError,
        variantInfo: classes.variantInfo,
        variantWarning: classes.variantInfo,
        variantSuccess: classes.variantSuccess,
        // @ts-ignore
        message: classes.message
      }}
      ContentProps={{
        classes: {
          root: classes.contentWrapper,
          action: classes.action
        }
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
