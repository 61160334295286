import { FC } from 'react';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Props } from './types';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  radioWrapper: {
    display: 'flex',
    cursor: 'pointer',
    padding: `${spacing(1.5)}px 0`,
    // @ts-ignore
    borderBottom: `1px solid ${palette.text.grey}`
  },
  outerCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `1px solid ${palette.primary.light}`,
    marginRight: spacing(2)
  },
  innerCircle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: palette.success.light
  },
  active: {
    borderColor: palette.success.light
  }
}));

const Radio: FC<Props> = ({ data, selectedValue, onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {data.map(({ text, value }) => (
        <Box
          key={value}
          className={classes.radioWrapper}
          onClick={() => onChange(value)}
        >
          <Typography variant="body1" color="textPrimary">
            {text}
          </Typography>
          <div
            className={clsx(
              classes.outerCircle,
              value === selectedValue && classes.active
            )}
          >
            {value === selectedValue && <div className={classes.innerCircle} />}
          </div>
        </Box>
      ))}
    </Box>
  );
};

export default Radio;
