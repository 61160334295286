import polyline from 'polyline-encoded';

export const getMapBounds = (
  selectedRoute: ItineraryType | null,
  from?: SelectedPointType | null,
  to?: SelectedPointType | null
) => {
  const delta = 0.0269; // this is roughly equal to 3 km

  if (selectedRoute && from && to) {
    return [
      [
        [from.lat, from.lng],
        [to.lat, to.lng]
      ],
      ...selectedRoute.legs.map((leg: LegType) =>
        polyline.decode(leg.legGeometry.points)
      )
    ].filter(a => a[0] && a[1]);
  }

  if (from) {
    return [
      [from.lat - delta, from.lng - delta],
      [from.lat + delta, from.lng + delta]
    ];
  }

  if (to) {
    return [
      [to.lat - delta, to.lng - delta],
      [to.lat + delta, to.lng + delta]
    ];
  }

  return null;
};
