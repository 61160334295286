import { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import {
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { browserHistory } from 'src/store';
import LoadingScreen from 'src/components/LoadingScreen';
import ScrollReset from 'src/components/ScrollReset';
import Notifier from 'src/components/Notifier';
import Routes from './routes';
import { NotistackProvider } from './components/NotistackProvider';

const jss = create({ plugins: [...jssPreset().plugins] });

const useStyles = makeStyles(() => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%',
      '-webkit-tap-highlight-color': 'transparent'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const App = () => {
  useStyles();
  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <NotistackProvider>
            <Router history={browserHistory}>
              <ScrollReset />
              <Suspense fallback={<LoadingScreen />}>
                <Routes />
                <Notifier />
              </Suspense>
            </Router>
          </NotistackProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
