import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import * as actions from '../actions/app';

export function* changeLocation({
  payload: { url }
}: ReturnType<typeof actions.navigateTo>): SagaIterator {
  const urlParams = { pathname: url };

  yield put(push(urlParams));
}

export default function* AppSagas(): SagaIterator {
  yield takeEvery(getType(actions.navigateTo), changeLocation);
}
