import { put, call, cancelled } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

export const safe = (saga: any, handler: any, cancelSource?: any) =>
  function* safeSaga(action: ActionType<any>) {
    try {
      yield call(saga, action);
    } catch (err) {
      const args = action.config ? [action.config, err] : [err];

      yield put(handler(...args));
    } finally {
      const args = action.config ? [action.config] : [];

      if (yield cancelled()) {
        yield put(handler(...args));
        cancelSource.cancel();
      }
    }
  };
