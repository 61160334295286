import { Box, Typography } from '@material-ui/core';

export const DeleteAccountView = () => (
  <Box width="100%" pt={5}>
    <Box display="flex" width="100%" justifyContent="center">
      <Typography variant="h5">Cancellation of user account</Typography>
    </Box>
    <Box mt={1} ml={6}>
      <Typography>
        <ol>
          <li>The logged in user opens mobile app settings.</li>
          <li>The user clicks on "Delete Account".</li>
          <li>The user selects option "Cancellation of account".</li>
          <li>The eShop asks user to confirm cancellation of account.</li>
          <li>
            The user confirms cancellation of the account by entering the
            password. This protects against "theft" of the account, for example
            in case when the user forgets to log out from the eShop.
          </li>
        </ol>
      </Typography>
    </Box>
  </Box>
);
