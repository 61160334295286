import { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { X as CloseIcon } from 'react-feather';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

type Props = {
  onClose: () => void;
  title: string;
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    padding: spacing(1.5),
    backgroundColor: palette.primary.main,
    borderRadius: 16,
    color: palette.common.white
  },
  closeWrapper: {
    marginLeft: spacing(0.5),
    cursor: 'pointer'
  },
  icon: {
    transform: 'rotate(180deg)'
  }
}));

const Alert: FC<Props> = ({ title, onClose }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mr={1.5}>
        <InfoOutlinedIcon className={classes.icon} />
      </Box>
      <Box flex={1}>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Box className={classes.closeWrapper} onClick={onClose}>
        <CloseIcon size={24} />
      </Box>
    </Box>
  );
};

export default Alert;
