import React, { ChangeEvent, useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Calendar, Clock } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Moment from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { useUtils } from '@material-ui/pickers';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import TimePicker from 'src/components/TimePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions';
import { TimeSettingsMode } from '../../types';
import { DATE_FORMAT, TIME_FORMAT } from '../../const';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  dateInput: {
    flex: 1
  },
  inputsWrapper: {
    display: 'flex',
    marginTop: spacing(2)
  },
  btn: {
    marginLeft: 'auto',
    maxWidth: 70,
    maxHeight: 40,
    borderRadius: 26,
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    '&:hover': {
      backgroundColor: palette.secondary.dark
    }
  },
  timePicker: {
    flex: 1,
    marginLeft: spacing(1),
    marginRight: spacing(1)
  },
  dottedLine: {
    width: '100%',
    height: 1,
    marginTop: spacing(2),
    backgroundImage: `linear-gradient(to right, ${palette.primary.light} 10%, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'top',
    backgroundSize: '7px 1px',
    backgroundRepeat: 'repeat-x'
  },
  icon: {
    marginRight: 3
  },
  calendar: {
    [breakpoints.up('sm')]: {
      marginLeft: spacing(2)
    },
    '@media (max-width: 330px)': {
      maxWidth: '100%',
      left: '4px !important'
    }
  }
}));

const TimeOptions = () => {
  const classes = useStyles();
  const { t } = useTranslation('trip');
  const utils = useUtils();
  const dispatch = useDispatch();
  const timeSettings = useSelector(selectors.selectTimeSettings);

  const [selectedTimeMode, setTimeMode] = useState<TimeSettingsMode>(
    timeSettings.mode
  );
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    new Moment().date()
  );
  const [selectedTime, setSelectedTime] = useState<ParsableDate>(new Date());
  const [timeValue, setTimeValue] = useState<string | null>();

  const handleDateChange = (date: MaterialUiPickersDate) =>
    setSelectedDate(date);

  const timeSettingsOptions = [
    {
      text: t('departNow'),
      value: TimeSettingsMode.DEPART_NOW
    },
    {
      text: t('startingTimeDate'),
      value: TimeSettingsMode.DEPART_AT
    },
    {
      text: t('arrivalTimeDate'),
      value: TimeSettingsMode.ARRIVE_BY
    }
  ];

  const handleTimeChange = (
    date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    setSelectedTime(date);
    setTimeValue(value);
  };

  const handleTimePickerChange = (value: number, picker: 'minute' | 'hour') => {
    const [hour = '0', minutes = '0'] = timeValue?.split(':') ?? [];
    const newHour = picker === 'hour' ? value : hour;
    const newMinutes = picker === 'minute' ? value : minutes;
    const newValue = moment(`${newHour}:${newMinutes}`, TIME_FORMAT);

    setTimeValue(newValue.format(TIME_FORMAT));
    setSelectedTime(newValue);
  };

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === TimeSettingsMode.DEPART_NOW) {
      dispatch(
        actions.setTimeSetting({
          mode: TimeSettingsMode.DEPART_NOW,
          date: moment().format(DATE_FORMAT),
          time: moment().format(TIME_FORMAT)
        })
      );
    }

    setTimeMode(e.target.value as TimeSettingsMode);
  };

  const handleSubmit = () => {
    const formatedDate = selectedDate?.format(DATE_FORMAT) as string;
    const formatedTime = moment(selectedTime).format(TIME_FORMAT);

    const isPast = moment(`${formatedDate} ${formatedTime}`).isBefore(
      moment(),
      'minute'
    );

    dispatch(
      actions.setTimeSetting({
        mode: selectedTimeMode,
        date: isPast ? moment().format(DATE_FORMAT) : formatedDate,
        time: isPast ? moment().format(TIME_FORMAT) : formatedTime
      })
    );

    if (isPast) {
      setSelectedDate(new Moment().date());
      setSelectedTime(new Date());
      setTimeValue(moment(new Date()).format(TIME_FORMAT));
    }
  };

  const isVaildDate =
    utils.isValid(selectedDate) && utils.isValid(selectedTime);

  return (
    <Box mt={2.5}>
      <Select
        id="time-settings"
        data={timeSettingsOptions}
        selectedValue={selectedTimeMode}
        onChange={handleChange}
      />
      {selectedTimeMode !== TimeSettingsMode.DEPART_NOW && (
        <Box display="flex" flexDirection="column">
          <Box className={classes.inputsWrapper}>
            <DatePicker
              variant="inline"
              disableToolbar
              format="DD.MM.yyyy"
              id="date-picker"
              minDate={new Date()}
              className={classes.dateInput}
              keyboardIcon={<Calendar size={20} className={classes.icon} />}
              helperText={t('invalidDate')}
              PopoverProps={{
                classes: {
                  paper: classes.calendar
                }
              }}
              value={selectedDate}
              onChange={handleDateChange}
            />
            <TimePicker
              variant="inline"
              disableToolbar
              ampm={false}
              id="time-picker"
              wrapperClasses={classes.timePicker}
              keyboardIcon={<Clock size={20} className={classes.icon} />}
              helperText={t('invalidTime')}
              value={selectedTime}
              onChange={handleTimeChange}
              onTimePickerChange={handleTimePickerChange}
            />
            <Button
              onClick={handleSubmit}
              className={classes.btn}
              disabled={!isVaildDate}
            >
              OK
            </Button>
          </Box>
          <Box className={classes.dottedLine} />
        </Box>
      )}
    </Box>
  );
};

export default TimeOptions;
