import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Divider,
  makeStyles,
  useMediaQuery,
  Button,
  Typography
} from '@material-ui/core';
import { Star } from 'react-feather';
import { ReactComponent as Dots } from 'src/assets/icons/dots.svg';
import { ReactComponent as StartPointIcon } from 'src/assets/icons/start-point.svg';
import { ReactComponent as Revert } from 'src/assets/icons/swap-vert-black.svg';
import { ReactComponent as DestinationIcon } from 'src/assets/icons/destination-point.svg';
import clsx from 'clsx';
import { selectIsStarShown } from 'src/store/selectors';
import * as selectors from '../store/selectors';
import * as actions from '../store/actions';
import { PointType } from '../types';
import { getPointName } from '../utils';
import SearchField from './SearchField';

interface Props {
  autofocus: boolean;
}

export const useStyles = makeStyles(({ spacing, palette }) => ({
  clearAllWrapper: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    background: palette.grey[100],
    padding: `${spacing(0.5)}px ${spacing(2)}px ${spacing(1.5)}px`
  },
  searchFields: {
    display: 'flex',
    backgroundColor: palette.common.white,
    width: '100%',
    minHeight: 88,
    borderRadius: 16,
    marginBottom: 1,
    '&$withClearAllWrapper': {
      marginTop: spacing(-1)
    }
  },
  activeFields: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  withClearAllWrapper: {},
  repeatIconWrapper: {
    height: 32,
    width: 32,
    marginLeft: spacing(0.5),
    padding: spacing(0.5),
    alignSelf: 'center'
  },
  starIconWrapper: {
    height: 32,
    width: 32,
    backgroundColor: `${palette.success.main}1a`,
    marginLeft: spacing(0.5),
    marginRight: spacing(1),
    padding: spacing(0.5),
    alignSelf: 'center'
  },
  revertIcon: {
    alignSelf: 'center',
    color: palette.primary.light
  },
  starIcon: {
    color: palette.success.main,
    fill: palette.success.main,
    opacity: 0.3,
    fillOpacity: 0.4
  },
  activeStar: {
    color: palette.success.main,
    fill: palette.success.main,
    opacity: 1,
    fillOpacity: 1
  },
  starHidden: {
    marginRight: spacing(1.5)
  },
  clearAllText: {
    color: palette.secondary.main
  }
}));

const RouteSearchFields = ({ autofocus }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('trip');
  const isDesktop = useMediaQuery('(min-width:600px)');

  const isFavoriteAndAuthenticated = useSelector(selectIsStarShown);
  const startPoint = useSelector(selectors.selectStartPoint);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const seletedFavoriteRoute = useSelector(
    selectors.selectFavoriteRouteSelected
  );

  const [startValue, setStartValue] = useState(
    startPoint ? getPointName(startPoint) : ''
  );
  const [destinationValue, setDestinationValue] = useState(
    destinationPoint ? getPointName(destinationPoint) : ''
  );

  const [isActive, setIsActive] = useState(false);

  const startRef = useRef<HTMLInputElement>(null);
  const destinationRef = useRef<HTMLInputElement>(null);

  const isStarShown =
    isFavoriteAndAuthenticated && startPoint && destinationPoint;

  const isRevertDisabled =
    !startPoint || !destinationPoint || !startValue || !destinationValue;

  const reverseStartDestination = () => {
    setStartValue(destinationValue);
    setDestinationValue(startValue);
    dispatch(actions.reversePoints());
  };

  const setStartPoint = useCallback(
    (route: SelectedPointType) => {
      dispatch(
        actions.setPoint({
          point: route,
          pointType: PointType.START
        })
      );
    },
    [dispatch]
  );

  const setDestinationPoint = useCallback(
    (route: SelectedPointType) => {
      dispatch(
        actions.setPoint({
          point: route,
          pointType: PointType.DESTINATION
        })
      );
    },
    [dispatch]
  );

  const handleClearAll = () => dispatch(actions.resetTrip());

  const createFavorite = () => dispatch(actions.createFavoriteRoute.request());

  const deleteFavorite = () =>
    dispatch(
      actions.deleteFavoriteRoute.request(seletedFavoriteRoute?.id as number)
    );

  const handleStarClick = () =>
    seletedFavoriteRoute ? deleteFavorite() : createFavorite();

  useEffect(() => {
    if (startPoint && !destinationPoint) {
      destinationRef.current?.focus();
    }

    if (destinationPoint && !startPoint) {
      startRef.current?.focus();
    }

    if (destinationPoint && startPoint) {
      startRef.current?.blur();
      destinationRef.current?.blur();
    }

    setStartValue(startPoint ? getPointName(startPoint) : '');
    setDestinationValue(destinationPoint ? getPointName(destinationPoint) : '');
  }, [startRef, destinationRef, startPoint, destinationPoint]);

  return (
    <>
      {startPoint && destinationPoint && (
        <Box
          className={classes.clearAllWrapper}
          display="flex"
          justifyContent="flex-end"
        >
          <Button onClick={handleClearAll}>
            <Typography variant="button" className={classes.clearAllText}>
              {t('clearAll')}
            </Typography>
          </Button>
        </Box>
      )}
      <Box
        className={clsx(
          classes.searchFields,
          isActive && classes.activeFields,
          startPoint && destinationPoint && classes.withClearAllWrapper
        )}
        boxShadow={2}
      >
        <Box
          my={{ xs: 1.5, sm: 2.5 }}
          px={1.5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <StartPointIcon />
          <Dots />
          <DestinationIcon />
        </Box>
        <Box
          my={{ xs: 1, sm: 1.5 }}
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="space-between"
        >
          <SearchField
            autoFocus={!startPoint && autofocus}
            ref={startRef}
            type={PointType.START}
            value={startValue}
            placeholder={t('searchStartPlaceholder')}
            setValue={setStartValue}
            setPoint={setStartPoint}
            handleActive={setIsActive}
          />
          <Box my={isDesktop ? 1 : 0.5}>
            <Divider />
          </Box>
          <SearchField
            autoFocus={!!startPoint && autofocus}
            ref={destinationRef}
            type={PointType.DESTINATION}
            value={destinationValue}
            placeholder={t('searchDestinationPlaceholder')}
            setValue={setDestinationValue}
            setPoint={setDestinationPoint}
            handleActive={setIsActive}
          />
        </Box>
        <IconButton
          color="secondary"
          className={clsx(
            classes.repeatIconWrapper,
            !isStarShown && classes.starHidden
          )}
          disabled={isRevertDisabled}
          onClick={reverseStartDestination}
        >
          <Revert className={classes.revertIcon} />
        </IconButton>
        {isStarShown && (
          <IconButton
            color="secondary"
            classes={{
              root: classes.starIconWrapper
            }}
            onClick={handleStarClick}
          >
            <Star
              className={clsx(
                classes.starIcon,
                seletedFavoriteRoute && classes.activeStar
              )}
            />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default RouteSearchFields;
