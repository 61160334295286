import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { loadUser } from 'redux-oidc';
import userManager from 'src/utils/userManager';

import { persistStore } from 'redux-persist';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const browserHistory = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducers = combineReducers({
  ...rootReducer,
  router: connectRouter(browserHistory)
});

const store = createStore(
  rootReducers,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory))
  )
);

sagaMiddleware.run(rootSaga);

loadUser(store, userManager);

export const persistor = persistStore(store);

export default store;
