import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  find,
  flowRight,
  head,
  isEqual,
  partition,
  sortBy,
  uniqWith
} from 'lodash';
import moment, { Moment } from 'moment';
import { LANGS } from 'src/constants';
import { Languages, RouteOptions } from 'src/types/enums';
import * as turf from '@turf/turf';
import { makeTransliteration } from 'src/utils/transliteration';
import { MAX_WAIT_TIME, MIN_WALKING_TIME, TIME_FORMAT } from './const';
import {
  AutocompletePointType,
  Coordinate,
  FavoritePlaceType,
  Layers,
  LocationPoint,
  Modes,
  StopPatternsResponce,
  SuggestedRouteWarning,
  TimeSettings,
  TimeSettingsMode,
  TimetableResponce
} from './types';

export const secondsToMinutes = (seconds: number) => {
  if (seconds === 0) {
    return seconds;
  }

  if (seconds < 60) {
    return 1;
  }

  return Math.round(seconds / 60);
};

export const secondsToMinutesAndHours = (seconds: number) => {
  if (seconds < 60) {
    return {
      hours: 0,
      minutes: 1
    };
  }

  const minutes = Math.round(seconds / 60);

  if (minutes < 60) {
    return {
      hours: 0,
      minutes
    };
  }

  return {
    hours: Math.round(minutes / 60),
    minutes: minutes % 60
  };
};

export const getTooltipText = (t: any, startTime: number) => {
  const now = moment();
  const start = moment(startTime);
  const diff = moment.duration(now.diff(start));
  const diffMinutes = Math.floor(Math.abs(diff.asMinutes()));
  const diffDays = start.dayOfYear() - now.dayOfYear();

  if (diffMinutes <= 1) {
    return t('now');
  }

  if (diffMinutes <= 30) {
    return t('inMin').replace('MINUTES_COUNT', `${diffMinutes}`);
  }

  if (diffDays === 1) {
    return `${start.format(TIME_FORMAT)} | ${t('tomorrow')}`;
  }

  return `${start.format(TIME_FORMAT)}${
    diffDays > 1 ? ` | ${start.format('DD.MM.yyyy')}` : ''
  }`;
};

export const formatDepartureDate = (
  t: any,
  date: Moment,
  mode: TimeSettingsMode
): string => {
  const diffDays = date.dayOfYear() - moment().dayOfYear();
  const isDeparture =
    mode === TimeSettingsMode.DEPART_AT || mode === TimeSettingsMode.DEPART_NOW;

  if (diffDays === 1) {
    return `${t('tomorrow')} / ${date.format(TIME_FORMAT)}`;
  }
  if (diffDays === 0) {
    return `${isDeparture ? t('at') : ''} ${date.format(TIME_FORMAT)}`;
  }
  return `${isDeparture ? t('on') : ''} ${date.format('DD.MM.yyyy / HH:mm')}`;
};

const isShortWalking = (items: ItineraryType[]): boolean => {
  const walkingOnly = find(
    items,
    item => item.legs.length === 1 && head(item.legs)?.mode === Modes.WALK
  );

  return (
    Boolean(walkingOnly?.duration) &&
    (walkingOnly?.duration as number) <= MIN_WALKING_TIME
  );
};

export const showWarning = (
  departureTime: Moment,
  items: ItineraryType[],
  isArriveBy: boolean
): SuggestedRouteWarning => {
  const item = head(items);

  if (!item) return 'noRoutesFound';

  if (
    !departureTime ||
    -moment
      .duration(
        departureTime?.diff(isArriveBy ? item?.endTime : item?.startTime)
      )
      .asMinutes() >= MAX_WAIT_TIME
  ) {
    return 'suggestedRoutesWarning';
  }
  if (isShortWalking(items)) {
    return 'noRoutesBetweenPointsWarning';
  }

  return null;
};

export const autocompletePointToSelectedPoint = (
  item: AutocompletePointType
): SelectedPointType => {
  let address = '';
  const isSame =
    `${item?.properties?.street} ${item?.properties?.housenumber ??
      ''}`.trim() === item?.properties?.name;

  if (item?.properties?.street && !isSame) {
    address = `${item?.properties?.street}${
      item?.properties?.housenumber ? `, ${item?.properties?.housenumber}` : ''
    }`;
  }

  return {
    address,
    lat: item?.geometry.coordinates[1] ?? 0,
    lng: item?.geometry.coordinates[0] ?? 0,
    name: item?.properties?.name,
    locality: item?.properties.locality,
    layer: item?.properties?.layer
  };
};

export const getPointName = ({ name, lat, lng }: SelectedPointType): string =>
  name || `${lat.toFixed(5)}, ${lng.toFixed(5)}`;

export const getPointDetailsName = ({
  name,
  address,
  layer,
  lat,
  lng
}: SelectedPointType): string => {
  if (name) {
    return layer && (layer === Layers.Venue || layer === Layers.Stop)
      ? `${name}, ${address || ''}`
      : name;
  }

  return `${lat.toFixed(5)}, ${lng.toFixed(5)}`;
};

const filterItineraries = (
  timeSettingsMode: TimeSettingsMode,
  routeOption: RouteOptions
) => (itineraries: ItineraryType[]) => {
  if (timeSettingsMode === TimeSettingsMode.ARRIVE_BY) {
    return itineraries?.filter(itinerary =>
      moment(itinerary.startTime).isAfter(moment())
    );
  }

  if (routeOption === RouteOptions.LOW_FLOOR) {
    return itineraries?.filter(
      ({ legs }) => legs.length !== 1 || legs[0]?.mode !== Modes.WALK
    );
  }

  return itineraries;
};

const divideItineraries = (
  itineraries: ItineraryType[],
  timeSettings: TimeSettings
) =>
  partition(
    itineraries,
    it =>
      moment
        .duration(
          moment(it.startTime).diff(
            moment(`${timeSettings?.date} ${timeSettings?.time}`)
          )
        )
        .asMinutes() < MAX_WAIT_TIME
  );

const sortDividedItineraries = (
  dividedItineraries: ItineraryType[][],
  sortOptions: any
) => [
  ...sortBy(dividedItineraries[0], sortOptions),
  ...sortBy(dividedItineraries[1], sortOptions)
];

const arrivalTimeRounding = ({ endTime }: ItineraryType) =>
  moment(endTime).format('YYYYMMDDHHmm');

const walkTimeRounding = ({ walkTime }: ItineraryType) =>
  Math.round(walkTime / 60);

const sortItineraries = (
  routeOption: RouteOptions,
  timeSettings: TimeSettings
) => (itineraries: ItineraryType[]) => {
  const routeOptionsSortingMap: Record<RouteOptions, ItineraryType[]> = {
    [RouteOptions.LOW_FLOOR]: sortDividedItineraries(
      divideItineraries(itineraries, timeSettings),
      [
        ({ legs }: ItineraryType) =>
          legs.length === 1 && legs[0].mode === Modes.WALK,
        arrivalTimeRounding
      ]
    ),
    [RouteOptions.LESS_WALKING]: sortDividedItineraries(
      divideItineraries(itineraries, timeSettings),
      [walkTimeRounding, arrivalTimeRounding]
    ),
    [RouteOptions.FEWER_TRANSFERS]: sortDividedItineraries(
      divideItineraries(itineraries, timeSettings),
      [
        ({ legs }: ItineraryType) => legs.length,
        arrivalTimeRounding,
        'duration'
      ]
    ),
    [RouteOptions.BEST_ROUTE]: sortBy(itineraries, [
      arrivalTimeRounding,
      walkTimeRounding
    ])
  };

  return routeOptionsSortingMap[routeOption];
};

export const mapItineraries = (
  routeOption: RouteOptions,
  timeSettings: TimeSettings,
  itineraries?: ItineraryType[]
) =>
  itineraries
    ? flowRight([
        sortItineraries(routeOption, timeSettings),
        filterItineraries(timeSettings.mode, routeOption)
      ])(uniqWith(itineraries, isEqual))
    : itineraries;

export const getFavoriteId = (
  address: SelectedPointType,
  favoritePlaces?: FavoritePlaceType[]
) =>
  find(
    favoritePlaces,
    favorite => favorite?.lat === address?.lat && favorite?.lng === address?.lng
  )?.id;

export const arrivalTimeFromMidnight = (
  secondsFromMidnight: number,
  serviceDay: number
) => {
  const currentUnix = moment().unix();
  const arrivalUnix = serviceDay + secondsFromMidnight;
  const timeDifference = arrivalUnix - currentUnix;

  if (timeDifference < 30) {
    return '<1';
  }

  return Math.round(timeDifference / 60);
};

export const cutTransportName = (name: string) => {
  const transportLetters = name.split('');
  const firstNumberIndex = transportLetters.findIndex(letter =>
    parseInt(letter, 10)
  );

  const firstLetter = transportLetters[firstNumberIndex];

  const transportName =
    firstLetter === '0'
      ? transportLetters.splice(firstNumberIndex + 1)
      : transportLetters.splice(firstNumberIndex);

  return transportName.join('');
};

export const getRouteWithLastPatternVersion = (
  data: StopPatternsResponce | undefined
) =>
  data?.stop?.patterns
    .map(({ code, route }) => ({
      code,
      ...route
    }))
    .filter((route, index, routesArray) => {
      const sameRoutes = routesArray.filter(
        r => r.shortName === route.shortName
      );

      if (sameRoutes.length === 1) return true;

      return (
        Math.max(
          ...sameRoutes.map(r => parseInt(r.code.replace(/:/g, ''), 10))
        ) === parseInt(route.code.replace(/:/g, ''), 10)
      );
    });

export const arrivalTimeFromMidnightToTime = (
  secondsFromMidnight: number,
  serviceDay: number
) => {
  const scheduledUnix = serviceDay + secondsFromMidnight;
  return moment.unix(scheduledUnix).format(TIME_FORMAT);
};

export const showTodayOrDate = (date?: MaterialUiPickersDate): string => {
  if (!date) return '';

  const momentDate = moment(date);

  return moment().diff(momentDate) < 0
    ? momentDate.format('DD.MM.YY')
    : 'today';
};

export const sortTimeTableData = (timetableData?: TimetableResponce) =>
  sortBy(
    timetableData?.stop.stopTimesForPattern,
    ({ serviceDay, scheduledArrival }) => serviceDay + scheduledArrival
  );

export const getStopsText = (
  length: number,
  language: string,
  t: (s: string) => string
) => {
  // For сonjugation ukrainian words
  const сonjugationArray = [2, 3, 4, 22, 23, 24, 32, 33, 34];
  const сonjugationSingleArray = [1, 21, 31, 41];

  if (language === LANGS.UK) {
    if (сonjugationSingleArray.includes(length))
      return `${length} ${t('stop')}`;

    return `${length} ${
      сonjugationArray.includes(length) ? t('stopsListTitle') : t('stops')
    }`;
  }

  return `${length} ${length > 1 ? t('stops') : t('stop')}`;
};

export const excludeWordsFromSearch = (search: string, words: string[]) => {
  // replace all 1 or more digits with format 'digits' and split string by whitespace
  const searchWords = search.replace(/\d+/g, "'$&'").split(/\s+/);

  return searchWords.filter(word => !words.includes(word)).join(' ');
};

export const getDistanceOfLocations = (from: Coordinate, to: Coordinate) => {
  const fromPoint = turf.point([from.lng, from.lat]);
  const toPoint = turf.point([to.lng, to.lat]);

  return turf.distance(fromPoint, toPoint);
};

export const findClosestLocation = (
  targetLocation: Coordinate,
  locations: LocationPoint[]
) => {
  let minDistance = Infinity;
  let closestLocation;

  for (const location of locations) {
    const distance = getDistanceOfLocations(targetLocation, location);

    if (distance < minDistance) {
      minDistance = distance;
      closestLocation = location;
    }
  }

  return closestLocation?.id;
};

export const getLocationInformationByLocalization = (
  selectedLocation: Coordinate,
  language: Languages,
  locations: AutocompletePointType[]
) => {
  const localizationFeature = {
    [LANGS.UK]: 'city_of_lviv_ua',
    [LANGS.EN]: 'city_of_lviv_en'
  };

  const feature = localizationFeature[language];

  const filteredLocations = locations.filter(location =>
    location.properties.source_id.includes(feature)
  );

  if (!filteredLocations.length) {
    const street = makeTransliteration(language)(
      locations[0].properties.street
    );

    return {
      ...locations[0],
      properties: {
        ...locations[0].properties,
        street
      }
    };
  }

  if (filteredLocations.length > 1) {
    const locationPoints = filteredLocations.map(
      ({ properties, geometry }) => ({
        id: properties.id,
        lng: geometry.coordinates[0],
        lat: geometry.coordinates[1]
      })
    );

    const closestLocationId = findClosestLocation(
      selectedLocation,
      locationPoints
    );

    return filteredLocations.find(
      location => location.properties.id === closestLocationId
    )!;
  }

  return filteredLocations[0];
};

export const sortAutocompletePoints = (points: AutocompletePointType[]) => {
  const sortLayerOrder: Record<string, number> = {
    street: 1,
    stop: 2
  };

  return points.sort((a, b) => {
    const orderA = sortLayerOrder[a.properties.layer] || 3;
    const orderB = sortLayerOrder[b.properties.layer] || 3;

    return orderA - orderB;
  });
};
