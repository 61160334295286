import { makeStyles, Typography, Box } from '@material-ui/core';
import { DirectionsWalk as WalkIcon } from '@material-ui/icons';
import { secondsToMinutes } from '../../utils';

const useStyles = makeStyles(({ palette, typography }) => ({
  icon: {
    fontSize: typography.body2.fontSize
  },
  legDot: {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: palette.grey[300],
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    position: 'relative'
  },
  legText: {
    position: 'absolute',
    top: 20,
    fontSize: typography.body2.fontSize,
    color: palette.grey[400]
  }
}));

const Leg = ({ duration }: LegType) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.legDot}>
      <WalkIcon fontSize="small" classes={{ fontSizeSmall: classes.icon }} />
      <Typography variant="body1" className={classes.legText}>
        {secondsToMinutes(duration)}
      </Typography>
    </Box>
  );
};

export default Leg;
