import { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ChevronDown, ChevronUp } from 'react-feather';
import clsx from 'clsx';

type Props = {
  title: string;
  className?: string;
  isOpen?: boolean;
  onClick?: () => void;
};

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  title: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: spacing(2),
    height: 24,
    width: 24,
    color: palette.primary.light
  }
}));

const FavoritesTitle: FC<Props> = ({
  title,
  className,
  isOpen,
  onClick = () => 0
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} onClick={onClick}>
      <Typography
        variant="button"
        color="textPrimary"
        className={classes.title}
      >
        {title}
      </Typography>
      {isOpen !== undefined &&
        (isOpen ? (
          <ChevronUp className={classes.expandIcon} />
        ) : (
          <ChevronDown className={classes.expandIcon} />
        ))}
    </Box>
  );
};

export default FavoritesTitle;
