import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'src/assets/css/prism.css';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { OidcProvider } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import { restoreSettings } from 'src/utils/settings';
import { OPEN_TRIP_PLANNER } from 'src/config';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import userManager from 'src/utils/userManager';
import { axiosInterceptor } from 'src/utils/api/apiClient';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { browserHistory, persistor } from './store';
import { SettingsProvider } from './context/SettingsContext';
import ErrorBoundary from './views/errors/ErrorBoundary';
import './apm-rum';
import './i18n';

export const apolloClient = new ApolloClient({
  uri: OPEN_TRIP_PLANNER,
  cache: new InMemoryCache()
});

enableES5();

const settings = restoreSettings();
axiosInterceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <OidcProvider store={store} userManager={userManager}>
        <SettingsProvider settings={settings}>
          <ConnectedRouter history={browserHistory}>
            <ApolloProvider client={apolloClient}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </ApolloProvider>
          </ConnectedRouter>
        </SettingsProvider>
      </OidcProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
