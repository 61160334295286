import { FC, useMemo } from 'react';
import { IconProps } from 'react-feather';
import {
  divIcon,
  DragEndEvent,
  LatLng,
  LatLngTuple,
  PointExpression
} from 'leaflet';
import { Marker } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';

interface Props {
  location: SelectedPointType | null;
  iconAnchor: PointExpression;
  MapMarkerIcon: FC<IconProps>;
  handleDragging?: (cor: LatLng) => void;
  draggable?: boolean;
}

const Endpoint = ({
  location,
  iconAnchor,
  MapMarkerIcon,
  handleDragging,
  draggable
}: Props) => {
  const position =
    location &&
    location.lat &&
    location.lng &&
    ([location.lat, location.lng] as LatLngTuple);

  const eventHandlers = useMemo(
    () => ({
      dragend({ target }: DragEndEvent) {
        if (handleDragging) {
          const coordinates = target.getLatLng();
          handleDragging(coordinates);
        }
      }
    }),
    [handleDragging]
  );

  if (!position) return null;

  const iconHtml = ReactDOMServer.renderToStaticMarkup(<MapMarkerIcon />);

  return (
    <Marker
      icon={divIcon({ html: iconHtml, className: '', iconAnchor })}
      zIndexOffset={1000}
      position={position}
      eventHandlers={eventHandlers}
      draggable={draggable}
    />
  );
};

export default Endpoint;
