import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { v4 as uuidv } from 'uuid';
import clsx from 'clsx';
import {
  Box,
  Typography,
  Card,
  Divider,
  Button,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useLazyQuery } from '@apollo/client';
import useLazyQueryError from 'src/hooks/useLazyQueryError';
import { RouteOptions } from 'src/types/enums';
import Loader from 'src/components/Loader';
import useLoadingDebounce from 'src/hooks/useLoadingDebounce';
import Alert from './Alert';
import { secondsToMinutes, showWarning, mapItineraries } from '../../utils';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions';
import {
  Modes,
  TimeSettingsMode,
  SuggestedRouteWarning,
  ViewModes
} from '../../types';
import {
  DATE_FORMAT,
  GET_ROUTES_QUERY,
  MAX_LEGS,
  MAX_WALK_DISTANCE,
  TIME_FORMAT
} from '../../const';
import RouteDuration from './RouteDuration';
import StartPoint from './StartPoint';
import DestinationPoint from './DestinationPoint';
import CollapsedLegs from './CollapsedLegs';
import TransportLeg from './TransportLeg';
import WalkLeg from './WalkLeg';
import { useStyles } from './useStyles';

const SuggestedRoutes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('trip');
  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const [shownWarning, setShownWarning] = useState<SuggestedRouteWarning>(null);
  const [activeRouteIndex, setActiveRouteIndex] = useState(0);
  const startPoint = useSelector(selectors.selectStartPoint);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const routeOption = useSelector(selectors.selectRouteOption);
  const timeSettings = useSelector(selectors.selectTimeSettings);
  const [getSuggestedRoutes, { data, loading, error }] = useLazyQuery(
    GET_ROUTES_QUERY,
    {
      fetchPolicy: 'network-only'
    }
  );
  const debouncedLoading = useLoadingDebounce(loading);
  const [itineraries, setItineraries] = useState<ItineraryType[] | undefined>(
    mapItineraries(routeOption, timeSettings, data?.plan?.itineraries)
  );

  const setSelectedRoute = useCallback(
    (payload: ItineraryType | null) =>
      dispatch(actions.setSelectedRoute(payload)),
    [dispatch]
  );

  const isSamePoints =
    startPoint?.lat === destinationPoint?.lat &&
    startPoint?.lng === destinationPoint?.lng;

  const handleGetSuggestedRoutes = useCallback(() => {
    if (startPoint && destinationPoint && !isSamePoints) {
      if (timeSettings.mode === TimeSettingsMode.DEPART_NOW) {
        dispatch(
          actions.setTimeSetting({
            mode: TimeSettingsMode.DEPART_NOW,
            date: moment().format(DATE_FORMAT),
            time: moment().format(TIME_FORMAT)
          })
        );
      }
      getSuggestedRoutes({
        variables: {
          fromPlace: `${startPoint.lat},${startPoint.lng}`,
          toPlace: `${destinationPoint.lat},${destinationPoint.lng}`,
          date: timeSettings.date,
          time: timeSettings.time,
          maxWalkDistance: MAX_WALK_DISTANCE,
          numItineraries: 10,
          wheelchair: routeOption === RouteOptions.LOW_FLOOR,
          arriveBy: timeSettings.mode === TimeSettingsMode.ARRIVE_BY
        }
      });
    } else {
      setItineraries([]);
      setShownWarning('noRoutesBetweenPointsWarning');
    }
  }, [
    startPoint,
    destinationPoint,
    timeSettings.mode,
    timeSettings.date,
    timeSettings.time,
    isSamePoints,
    dispatch,
    getSuggestedRoutes,
    routeOption
  ]);

  const handleCardClick = (itinerary: ItineraryType, index: number) => {
    !isDesktopScreen && showRouteDetails(itinerary);
    setActiveRouteIndex(index);
  };

  useLazyQueryError(error);

  useEffect(() => {
    setActiveRouteIndex(0);
    setItineraries(
      mapItineraries(routeOption, timeSettings, data?.plan?.itineraries)
    );
  }, [data, routeOption, timeSettings]);

  useEffect(() => {
    handleGetSuggestedRoutes();
  }, [handleGetSuggestedRoutes]);

  useEffect(() => {
    if (itineraries && !isSamePoints) {
      setShownWarning(
        showWarning(
          moment(`${timeSettings?.date} ${timeSettings?.time}`),
          itineraries,
          timeSettings.mode === TimeSettingsMode.ARRIVE_BY
        )
      );
      setSelectedRoute(itineraries[activeRouteIndex]);
    } else {
      setSelectedRoute(null);
    }
  }, [
    itineraries,
    setSelectedRoute,
    activeRouteIndex,
    timeSettings,
    isSamePoints
  ]);

  const showRouteDetails = useCallback(
    (payload: ItineraryType) => {
      dispatch(actions.setSelectedRoute(payload));
      dispatch(actions.setViewMode(ViewModes.ROUTE_DETAILS_VIEW));
    },
    [dispatch]
  );

  return (
    <Box px={2} py={1} className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        height={36}
        className={classes.headerWrapper}
      >
        <Typography
          variant="button"
          color="textPrimary"
          className={classes.heading}
        >
          {t('suggestedRoutes')}
        </Typography>
        {data && (
          <IconButton
            onClick={handleGetSuggestedRoutes}
            className={classes.refreshWrapper}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      {debouncedLoading && (
        <Box
          display="flex"
          height={200}
          width="100%"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          <Loader />
        </Box>
      )}
      {shownWarning && !debouncedLoading && (
        <Box mt={0.5}>
          {' '}
          <Alert
            title={t(shownWarning)}
            onClose={() => setShownWarning(null)}
          />
        </Box>
      )}
      {itineraries &&
        !debouncedLoading &&
        shownWarning !== 'noRoutesBetweenPointsWarning' &&
        // @ts-ignore
        itineraries.map((itinerary: ItineraryType, routeIndex: number) => {
          let aggregatedTransfers = 0;
          let aggregatedMinWalk = 0;
          let transportIndex = 0;

          return (
            <Card
              key={uuidv()}
              className={clsx(
                classes.route,
                routeIndex === activeRouteIndex && classes.active
              )}
              onClick={() => handleCardClick(itinerary, routeIndex)}
            >
              <RouteDuration
                duration={itinerary.duration}
                walkTime={itinerary.walkTime}
              />
              <Box
                height={30}
                key={uuidv()}
                mx={1}
                my={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="relative"
              >
                <StartPoint startTime={itinerary.startTime} />
                {itinerary.legs.map((leg: LegType, index: number) => {
                  const isWalk = leg.mode === Modes.WALK;
                  const isLegsCollapsed = itinerary.legs.length > MAX_LEGS;

                  if (isLegsCollapsed && index > 1) {
                    if (isWalk) {
                      aggregatedMinWalk += secondsToMinutes(leg.duration);
                    } else {
                      aggregatedTransfers += 1;
                    }

                    return null;
                  }

                  if (!isWalk) {
                    transportIndex += 1;
                  }

                  return isWalk ? (
                    <WalkLeg key={uuidv()} {...leg} />
                  ) : (
                    <TransportLeg
                      key={uuidv()}
                      index={transportIndex}
                      {...leg}
                    />
                  );
                })}
                <CollapsedLegs
                  transfers={aggregatedTransfers}
                  walk={aggregatedMinWalk}
                />
                <DestinationPoint endTime={itinerary.endTime} />
              </Box>
              <Divider className={classes.divider} />
              {isDesktopScreen && (
                <Button
                  className={clsx(
                    classes.detailsBtn,
                    routeIndex === activeRouteIndex && classes.active
                  )}
                  onClick={() => showRouteDetails(itinerary)}
                >
                  {t('details')}
                </Button>
              )}
            </Card>
          );
        })}
    </Box>
  );
};

export default SuggestedRoutes;
