import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { LANGS, LANGS_LABELS } from 'src/constants';

const useStyles = makeStyles(() => ({
  select: {
    '&&': {
      paddingRight: 0
    }
  },
  selectRoot: {
    '&:focus': {
      background: 'none'
    }
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    minWidth: 50
  },
  menuItem: {
    fontSize: 14
  }
}));

function Language() {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextLang = event.target.value as string;
    i18n.changeLanguage(nextLang);
  };

  return (
    <Select
      autoWidth={true}
      classes={{ select: classes.select, root: classes.selectRoot }}
      value={i18n.language}
      onChange={handleChange}
      renderValue={value => (
        <IconButton className={classes.button}>
          <Typography
            className={classes.menuItem}
            variant="subtitle2"
            color="textSecondary"
          >
            {LANGS_LABELS[value as keyof typeof LANGS_LABELS]}
          </Typography>
        </IconButton>
      )}
      IconComponent={() => <></>}
      disableUnderline
    >
      <MenuItem value={LANGS.UK}>
        <Typography
          className={classes.menuItem}
          variant="subtitle2"
          color="textSecondary"
        >
          {LANGS_LABELS.uk}
        </Typography>
      </MenuItem>
      <MenuItem value={LANGS.EN}>
        <Typography
          className={classes.menuItem}
          variant="subtitle2"
          color="textSecondary"
        >
          {LANGS_LABELS.en}
        </Typography>
      </MenuItem>
    </Select>
  );
}

export default Language;
