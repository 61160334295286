import { createReducer, ActionType } from 'typesafe-actions';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { RouteOptions } from 'src/types/enums';
import * as actions from './actions';

export type ActionsTypes = ActionType<typeof actions>;

export type StateTypes = Readonly<{
  isFavoritesShown: boolean;
  routeOption: RouteOptions;
}>;

export const initialState: StateTypes = {
  isFavoritesShown: true,
  routeOption: RouteOptions.BEST_ROUTE
};

const reducer = createReducer<StateTypes, ActionsTypes>(initialState)
  .handleAction(actions.setIsFavoritesShown, (state, { payload }) => ({
    ...state,
    isFavoritesShown: payload
  }))
  .handleAction(actions.setGlobalRouteOption, (state, { payload }) => ({
    ...state,
    routeOption: payload
  }))
  .handleAction(actions.resetSettings, state => ({
    ...initialState
  }));

const persistConfig = {
  key: 'tripSettings',
  storage,
  whitelist: ['isFavoritesShown', 'routeOption']
};

export default persistReducer(persistConfig, reducer);
