import { RouteOptions } from 'src/types/enums';
import { createAction } from 'typesafe-actions';

export const setIsFavoritesShown = createAction(
  '@TRIP_SETTINGS/SET_IS_FAVORITES_SHOWN',
  (payload: boolean) => payload
)();

export const setGlobalRouteOption = createAction(
  '@TRIP_SETTINGS/SET_GLOBAL_ROUTE_OPTION',
  (payload: RouteOptions) => payload
)();

export const resetSettings = createAction('@TRIP_SETTINGS/RESET_SETTINGS')();
