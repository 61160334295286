import { MapLimits } from 'src/types/mapLimits';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from '../actions/mapLimits';

type ActionTypes = ActionType<typeof actions>;

type StateType = MapLimits | null;

export const initialState: StateType = null;

const appReducers = createReducer<StateType, ActionTypes>(
  initialState
).handleAction(actions.getMapLimits.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default appReducers;
