import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Store } from 'redux';
import { API_BASE_URL } from 'src/config';
import * as snackbarActions from 'src/store/actions/snackbar';
import { RootState } from 'typesafe-actions';

const apiClient = axios.create({ baseURL: API_BASE_URL });

export const axiosInterceptor = (store: Store<RootState>) => {
  apiClient.interceptors.request.use(config => {
    const token = store.getState().oidc.user?.access_token;

    return token
      ? {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`
          }
        }
      : config;
  });

  apiClient.interceptors.response.use(
    response => response,
    error => {
      const { UNPROCESSABLE_ENTITY, INTERNAL_SERVER_ERROR } = StatusCodes;

      if (error.response && error.response.status === UNPROCESSABLE_ENTITY) {
        return Promise.reject(error.response.data.errors);
      }

      if (error?.response?.status >= INTERNAL_SERVER_ERROR) {
        store.dispatch(
          snackbarActions.enqueueSnackbar({
            message: 'error',
            options: {
              variant: 'error'
            }
          })
        );
      }

      return Promise.reject(error);
    }
  );
};

export default apiClient;
