import { useState, memo } from 'react';
import { Box, makeStyles, Collapse } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FavoritesTitle from './FavoritesTitle';
import * as selectors from '../../store/selectors';
import FavoritesEmpty from './FavoritesEmpty';
import FavoriteRoute from './FavoriteRoute';

export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    width: '100%',
    backgroundColor: palette.common.white,
    borderRadius: 4,
    overflowY: 'auto'
  },
  title: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    cursor: 'pointer'
  },
  titleWrapper: {
    display: 'flex',
    borderRadius: 16,
    flexDirection: 'column',
    zIndex: 1001,
    backgroundColor: palette.common.white
  }
}));

const FavoriteRoutesList = () => {
  const classes = useStyles();
  const { t } = useTranslation('trip');
  const [isOpen, setIsOpen] = useState(false);

  const routes = useSelector(selectors.selectFavoriteRoutes);
  const start = useSelector(selectors.selectStartPoint);
  const destination = useSelector(selectors.selectDestinationPoint);
  const isLoading = useSelector(selectors.selectIsFavoriteRoutesLoading);

  const handleTitleClick = () => setIsOpen(!isOpen);

  if (start && destination) return null;

  return (
    <>
      <Box className={classes.titleWrapper}>
        <FavoritesTitle
          title={t('favoriteRoutes')}
          className={classes.title}
          onClick={handleTitleClick}
          isOpen={isOpen}
        />
      </Box>
      <Box className={classes.root}>
        <Collapse in={isOpen} timeout={0}>
          {isEmpty(routes) ? (
            <FavoritesEmpty
              title={t('favoriteRoutesTitle')}
              subTitle={t('noFavoriteRoutes')}
              isLoading={isLoading}
            />
          ) : (
            routes.map(route => <FavoriteRoute key={route.id} route={route} />)
          )}
        </Collapse>
      </Box>
    </>
  );
};

export default memo(FavoriteRoutesList);
