import { useTranslation } from 'react-i18next';
import { RouteOptions } from 'src/types/enums';

export default function useRouteOptions() {
  const { t } = useTranslation('trip');
  const routeOptionsData = [
    {
      text: t('bestRoute'),
      value: RouteOptions.BEST_ROUTE
    },
    {
      text: t('lessWalking'),
      value: RouteOptions.LESS_WALKING
    },
    {
      text: t('lowFloor'),
      value: RouteOptions.LOW_FLOOR
    },
    {
      text: t('fewerTransfers'),
      value: RouteOptions.FEWER_TRANSFERS
    }
  ];

  return routeOptionsData;
}
