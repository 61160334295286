import { useRef, useEffect, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Loader from 'src/components/Loader';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { selectIsFavoritesShown } from 'src/views/TripSettingsView/store/selectors';
import { selectIsAuthenticated } from 'src/store/selectors';
import * as selectors from '../store/selectors';
import RouteSearchFields from './RouteSearchFields';
import RouteOptions from './RouteOptions';
import SuggestedRoutes from './SuggestedRoutes';
import FavoriteRoutesList from './Favorites/FavoriteRoutesList';
import { UserAddressType } from '../types';

const SuggestedRouteDetails = lazy(() => import('./SuggestedRouteDetails'));
const UserAddressSearchField = lazy(() => import('./UserAddressSearchField'));
const StopDetails = lazy(() => import('./StopDetails'));

export const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  leftBarWrapper: {
    width: 375,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${spacing(6)}px)`,
    margin: spacing(3),
    position: 'absolute',
    zIndex: 1001,
    '&$fullHeightWrapper': {
      [breakpoints.up('sm')]: {
        height: `calc(100% - ${spacing(6)}px)`
      }
    },
    '&$fullScreenWrapper': {
      backgroundColor: palette.background.default,
      border: `1px solid ${palette.background.dark}`,
      borderRadius: 16,
      [breakpoints.up('sm')]: {
        height: `calc(100% - ${spacing(6)}px)`
      }
    },
    [breakpoints.down('xs')]: {
      width: `calc(100% - ${spacing(1)}px)`,
      maxHeight: `calc(100% - ${spacing(1)}px)`,
      margin: spacing(0.5),
      '&$fullScreenWrapper': {
        maxHeight: `calc(50% - ${spacing(1)}px)`,
        bottom: 0
      }
    }
  },
  leftBar: {
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    backgroundColor: palette.background.default,
    marginTop: spacing(0.5)
  },
  searchWrapper: {
    borderRadius: 16,
    backgroundColor: palette.background.default
  },
  suggestedRoutesWrapper: {
    marginTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    backgroundColor: palette.common.white
  },
  fullScreenWrapper: {},
  fullHeightWrapper: {}
}));

const SearchBar = () => {
  const classes = useStyles();
  const prevActiveUserAddress = useRef<UserAddressType | null>(null);

  const isRouteDetailsShown = useSelector(selectors.selectIsRouteDetailsShown);
  const isSearchShown = useSelector(selectors.selectIsSearchShown);
  const isFavoritesShown = useSelector(selectIsFavoritesShown);
  const isUserAddressShown = useSelector(selectors.selectIsUserAddressShown);
  const isStopDetailsShown = useSelector(selectors.selectIsStopDetailsShown);
  const isSuggestedRoutesShown = useSelector(
    selectors.selectIsSuggestedRoutesShown
  );
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const activeUserAddress = useSelector(selectors.selectActiveUserAddress);
  const isDesktopScreen = useMediaQuery('(min-width:600px)');
  const isRouteOptionsVisible = isDesktopScreen || isSuggestedRoutesShown;

  useEffect(() => {
    prevActiveUserAddress.current = activeUserAddress;
  }, [activeUserAddress]);

  return (
    <Box
      className={clsx(
        classes.leftBarWrapper,
        isSuggestedRoutesShown && classes.fullHeightWrapper,
        (isRouteDetailsShown || isStopDetailsShown) && classes.fullScreenWrapper
      )}
    >
      {isSearchShown && (
        <>
          <Box className={classes.searchWrapper} boxShadow={2}>
            <RouteSearchFields
              autofocus={!!prevActiveUserAddress.current && !activeUserAddress}
            />
            {isRouteOptionsVisible && <RouteOptions />}
          </Box>

          <Box
            className={clsx(
              classes.leftBar,
              isSuggestedRoutesShown && classes.suggestedRoutesWrapper
            )}
            boxShadow={2}
          >
            {isAuthenticated && isFavoritesShown && <FavoriteRoutesList />}
            {isSuggestedRoutesShown && <SuggestedRoutes />}
          </Box>
        </>
      )}
      <Suspense
        fallback={
          <Box className={classes.loaderWrapper}>
            <Loader />
          </Box>
        }
      >
        {isRouteDetailsShown && <SuggestedRouteDetails />}
        {isUserAddressShown && isAuthenticated && <UserAddressSearchField />}
        {isStopDetailsShown && <StopDetails />}
      </Suspense>
    </Box>
  );
};

export default SearchBar;
