import { Collapse } from '@material-ui/core';
import { FC, ReactElement, ReactNode, useState } from 'react';
import AccordionHead from './AccordionHead';

type Props = {
  title: ReactNode;
  icon: ReactElement;
};

const RouteAccordion: FC<Props> = ({ children, title, icon }) => {
  const [isExtended, setIsExtended] = useState(false);

  const toggleExtended = () => setIsExtended(!isExtended);

  return (
    <>
      <AccordionHead
        title={title}
        icon={icon}
        isExtended={isExtended}
        onClick={toggleExtended}
      />
      <Collapse in={isExtended}>{children}</Collapse>
    </>
  );
};

export default RouteAccordion;
