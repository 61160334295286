import { LatLngLiteral } from 'leaflet';
import { min, max } from 'lodash';
import { MapLimits } from 'src/types/mapLimits';

// Cut pelias request like configured on admin part
export const getRectBoundary = (mapLimits: MapLimits) => {
  if (!mapLimits) {
    return '';
  }

  return (
    `boundary.rect.max_lat=${max([
      mapLimits.workArea?.bottomRightPoint?.lat,
      mapLimits.workArea?.topLeftPoint?.lat
    ])}&` +
    `boundary.rect.max_lon=${max([
      mapLimits.workArea?.bottomRightPoint?.lng,
      mapLimits.workArea?.topLeftPoint?.lng
    ])}&` +
    `boundary.rect.min_lat=${min([
      mapLimits.workArea?.bottomRightPoint?.lat,
      mapLimits.workArea?.topLeftPoint?.lat
    ])}&` +
    `boundary.rect.min_lon=${min([
      mapLimits.workArea?.bottomRightPoint?.lng,
      mapLimits.workArea?.topLeftPoint?.lng
    ])}`
  );
};

export const getCoordinatesParams = (coordinates: LatLngLiteral) =>
  `point.lat=${coordinates.lat}&point.lon=${coordinates.lng}&layers=address`;
