import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowUp, Star, MapPin } from 'react-feather';
import {
  Box,
  colors,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { selectIsStarShown } from 'src/store/selectors';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BusStop } from 'src/assets/icons/24-bus-stop.svg';
import { ReactComponent as BusinessCenter } from 'src/assets/icons/24-business-center.svg';
import { AccessTime } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import { makeTransliteration } from 'src/utils/transliteration';
import * as actions from '../../store/actions';
import { Layers } from '../../types';
import { getPointName } from '../../utils';

type PropsType = {
  point: SelectedPointType;
  separator?: string;
  layer?: Layers | 'recent';
  fillField?: (text: string) => void;
  setPoint: (point: SelectedPointType) => void;
  favoriteId?: number;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative'
  },
  textWrapper: {
    flex: 1
  },
  rightIconWrapper: {
    marginLeft: spacing(1),
    marginRight: -spacing(1.5),
    padding: spacing(1)
  },
  arrowUp: {
    transform: 'rotate(-45deg)'
  },
  highlighted: {
    fontWeight: 'bold',
    background: 'transparent'
  },
  star: {
    color: palette.success.main,
    fill: palette.success.main,
    opacity: 0.3,
    fillOpacity: 0.1
  },
  filledStart: {
    color: palette.success.main,
    fill: palette.success.main,
    opacity: 1,
    fillOpacity: 1
  },
  recent: {
    color: palette.primary.light,
    width: 24,
    height: 24
  },
  lineWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderBottom: `1px solid ${palette.background.dark}`,
    marginRight: spacing(2.5),
    paddingBottom: spacing(2)
  }
}));

const PointOption: FC<PropsType> = ({
  point,
  separator = '',
  favoriteId,
  layer,
  fillField = () => 0,
  setPoint
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation('trip');
  const transliterate = makeTransliteration(i18n.language);
  const isStarShown = useSelector(selectIsStarShown);

  const locality = `${
    point.locality ? `${transliterate(point.locality)}, ` : ''
  }${t('ukraine')}`;
  const address = point.address ? `${transliterate(point.address)}` : locality;

  const createFavorite = () =>
    dispatch(
      actions.createFavoritePlace.request({
        ...point,
        address
      })
    );

  const deleteFavorite = (id: number) =>
    dispatch(actions.deleteFavoritePlace.request(id));

  const mapLayerIcon = {
    stop: <BusStop color={colors.blueGrey[600]} />,
    address: <MapPin size={18} color={colors.blueGrey[600]} />,
    street: <MapPin size={18} color={colors.blueGrey[600]} />,
    venue: <BusinessCenter color={colors.blueGrey[600]} />,
    recent: <AccessTime className={classes.recent} />
  };

  const fillSearchField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    fillField(point.name as string);
  };

  const handleStarClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (favoriteId) {
      deleteFavorite(favoriteId);
    } else {
      createFavorite();
    }
  };

  return (
    <Box mt={2} className={classes.root} onClick={() => setPoint(point)}>
      {layer && (
        <Box mx={2.5} pb={2}>
          {mapLayerIcon[layer]}
        </Box>
      )}
      <Box className={classes.lineWrapper}>
        <Box
          flexDirection="column"
          className={classes.textWrapper}
          ml={layer ? 0 : 4.5}
        >
          <Typography variant="body1" component="span">
            <Highlighter
              highlightClassName={classes.highlighted}
              searchWords={[separator]}
              autoEscape={true}
              textToHighlight={getPointName(point)}
            />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {point.address ? `${transliterate(point.address)}` : locality}
          </Typography>
        </Box>

        {layer === 'street' && separator !== point.name ? (
          <IconButton
            color="secondary"
            className={classes.rightIconWrapper}
            onClick={fillSearchField}
          >
            <ArrowUp color={colors.blueGrey[600]} className={classes.arrowUp} />
          </IconButton>
        ) : (
          isStarShown && (
            <IconButton
              color="secondary"
              className={classes.rightIconWrapper}
              onClick={handleStarClick}
            >
              <Star
                className={clsx(
                  classes.star,
                  favoriteId && classes.filledStart
                )}
              />
            </IconButton>
          )
        )}
      </Box>
    </Box>
  );
};

export default memo(PointOption);
