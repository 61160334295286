import { ReactComponent as StartingPoint } from 'src/assets/icons/map-starting.svg';
import { ReactComponent as DestinationPoint } from 'src/assets/icons/map-destination.svg';
import { useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { useMediaQuery } from '@material-ui/core';
import * as selectors from 'src/views/TripView/store/selectors';
import * as actions from 'src/views/TripView/store/actions';
import { PointType } from 'src/views/TripView/types';
import Endpoint from 'src/components/Map/Endpoint';
import { useTranslation } from 'react-i18next';

const Endpoints = () => {
  const dispatch = useDispatch();
  const {
    i18n: { language }
  } = useTranslation();
  const startPoint = useSelector(selectors.selectStartPoint);
  const destinationPoint = useSelector(selectors.selectDestinationPoint);
  const activeUserAddress = useSelector(selectors.selectActiveUserAddress);
  const isSearchView = useSelector(selectors.selectIsSearchShown);
  const isDesktopScreen = useMediaQuery('(min-width:600px)');

  useMapEvents({
    click({ latlng }: LeafletMouseEvent) {
      if (isDesktopScreen) {
        if (activeUserAddress) {
          return dispatch(
            actions.addUserAddressFromMap.request({
              coordinates: latlng,
              language
            })
          );
        }

        if (!startPoint || !destinationPoint) {
          return dispatch(
            actions.setPointFromMap.request({
              coordinates: latlng,
              pointType: !startPoint ? PointType.START : PointType.DESTINATION,
              language
            })
          );
        }

        return null;
      }

      return null;
    }
  });

  const handlePointDragging = (point: PointType) => (coordinates: LatLng) => {
    dispatch(
      actions.setPointFromMap.request({
        coordinates,
        pointType: point,
        language
      })
    );
  };

  return (
    <>
      {startPoint && (
        <Endpoint
          iconAnchor={[20, 20]}
          location={startPoint}
          MapMarkerIcon={StartingPoint}
          handleDragging={handlePointDragging(PointType.START)}
          draggable={isSearchView}
        />
      )}
      {destinationPoint && (
        <Endpoint
          iconAnchor={[18, 36]}
          location={destinationPoint}
          MapMarkerIcon={DestinationPoint}
          handleDragging={handlePointDragging(PointType.DESTINATION)}
          draggable={isSearchView}
        />
      )}
    </>
  );
};

export default Endpoints;
