import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError } from '@apollo/client';
import * as snackbarActions from 'src/store/actions/snackbar';

export default function useLazyQueryError(error: ApolloError | undefined) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(
        snackbarActions.enqueueSnackbar({
          message: 'error',
          options: {
            variant: 'error'
          }
        })
      );
    }
  }, [dispatch, error]);
}
