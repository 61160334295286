import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Cross } from 'src/assets/icons/snackbar/icons-material-close-default.svg';
import { removeSnackbar } from 'src/store/actions/snackbar';
import { selectSnackbar } from 'src/store/selectors';

let displayed: Array<SnackbarKey> = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('snackBar');
  const notifications = useSelector(selectSnackbar);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }: UINotification) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;
        // display snackbar using notistack
        enqueueSnackbar(t(message), {
          key,
          ...options,
          autoHideDuration: 5000,
          preventDuplicate: true,
          action: (
            <IconButton onClick={() => closeSnackbar(key)} color="primary">
              <Cross />
            </IconButton>
          ),
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (e, myKey: SnackbarKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          }
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

  return null;
};

export default Notifier;
