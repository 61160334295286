import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Divider,
  Switch,
  withStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { selectIsAuthenticated } from 'src/store/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useRouteOptions from 'src/hooks/useRouteOptions';
import { RouteOptions } from 'src/types/enums';
import { ChangeEvent } from 'react';
import Radio from '../../components/Radio/SettingsRadio';
import {
  selectIsFavoritesShown,
  selectGlobalRouteOptions
} from './store/selectors';
import * as actions from './store/actions';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    flexGrow: 1,
    marginTop: spacing(7)
  },
  page: {
    position: 'relative'
  },
  title: {
    fontSize: 18,
    fontWeight: 500
  },
  subTitle: {
    fontSize: 14,
    marginTop: spacing(7),
    marginBottom: spacing(1.5)
  },
  infoMessage: {
    fontSize: 14,
    color: palette.common.black,
    opacity: 0.5
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${spacing(1.5)}px 0`,
    marginTop: spacing(2)
  },
  showFavoritesText: {
    fontSize: 16
  },
  switch: {
    marginLeft: 'auto'
  }
}));

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

const TripSettingsView = () => {
  const classes = useStyles();
  const { t } = useTranslation('tripSettings');
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const routeOption = useSelector(selectGlobalRouteOptions);
  const isFavoritesShown = useSelector(selectIsFavoritesShown);
  const routeOptions = useRouteOptions();

  const handleSetRouteOption = (value: string) =>
    dispatch(actions.setGlobalRouteOption(value as RouteOptions));

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(actions.setIsFavoritesShown(!isFavoritesShown));

  return (
    <Page className={classes.page} title={t('tripSettingsTitle')}>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} sm={9} md={5}>
          <Box display="flex" flexDirection="column" px={{ xs: 3, sm: 0 }}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.title}
            >
              {t('tripSettings')}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              className={classes.subTitle}
            >
              {t('routeOptions')}
            </Typography>
            <Divider />
            <Box mt={3.5} mb={2}>
              <Radio
                data={routeOptions}
                selectedValue={routeOption}
                onChange={handleSetRouteOption}
              />
            </Box>
            <Typography variant="body2" className={classes.infoMessage}>
              {t('infoMessage')}
            </Typography>
            {isAuthenticated && (
              <>
                <Box mt={7} mb={1.5}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    className={classes.title}
                  >
                    {t('visibility')}
                  </Typography>
                </Box>
                <Divider />
                <Box className={classes.switchWrapper}>
                  <Typography variant="body1" color="textPrimary">
                    {t('showFavorites')}
                  </Typography>
                  <GreenSwitch
                    className={classes.switch}
                    onChange={handleSwitchChange}
                    checked={isFavoritesShown}
                  />
                </Box>
                <Divider />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default TripSettingsView;
