import { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { Sliders } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import Radio from 'src/components/Radio/TripRadio';
import moment from 'moment';
import useRouteOptions from 'src/hooks/useRouteOptions';
import { RouteOptions } from 'src/types/enums';
import clsx from 'clsx';
import * as actions from '../../store/actions';
import * as selectors from '../../store/selectors';
import { formatDepartureDate } from '../../utils';
import { TimeSettingsMode } from '../../types';
import RouteAccordion from './RouteAccordion';
import TimeOptions from './TimeOptions';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${spacing(2.5)}px ${spacing(2)}px`,
    backgroundColor: palette.background.default,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16
  },
  icon: {
    color: palette.primary.light,
    width: 20,
    height: 20
  },
  suggetedRoutesShown: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

const RouteOptionsComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation('trip');

  const dispatch = useDispatch();
  const routeOptions = useRouteOptions();
  const routeOption = useSelector(selectors.selectRouteOption);
  const timeSettings = useSelector(selectors.selectTimeSettings);
  const isSuggestedRoutesShown = useSelector(
    selectors.selectIsSuggestedRoutesShown
  );
  const setRouteOption = (value: string) =>
    dispatch(actions.setRouteOption(value as RouteOptions));

  const routeOptionsTitle = find(routeOptions, r => r.value === routeOption)
    ?.text;

  return (
    <Box
      className={clsx(
        classes.root,
        isSuggestedRoutesShown && classes.suggetedRoutesShown
      )}
    >
      <RouteAccordion
        title={
          <>
            {t(
              timeSettings.mode === TimeSettingsMode.ARRIVE_BY
                ? 'arriveBy'
                : 'departureAt'
            )}
            <b>
              &nbsp;
              {formatDepartureDate(
                t,
                moment(`${timeSettings?.date} ${timeSettings?.time}`),
                timeSettings.mode
              )}
            </b>
          </>
        }
        icon={<AccessTime className={classes.icon} />}
      >
        <Box mt={1}>
          <TimeOptions />
        </Box>
      </RouteAccordion>
      <Box mt={2}>
        <RouteAccordion
          title={routeOptionsTitle as string}
          icon={<Sliders className={classes.icon} />}
        >
          <Box mt={1} ml={4.5}>
            <Radio
              data={routeOptions}
              selectedValue={routeOption}
              onChange={setRouteOption}
            />
          </Box>
        </RouteAccordion>
      </Box>
    </Box>
  );
};

export default memo(RouteOptionsComponent);
