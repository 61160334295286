import { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Loader from 'src/components/Loader';
import { ReactComponent as PlaceholderStar } from 'src/assets/icons/placeholder-star.svg';

type Props = {
  title: string;
  subTitle?: string;
  isLoading?: boolean;
};

export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 220,
    overflowY: 'hidden'
  },
  placeholderStart: {
    marginTop: spacing(5.5),
    alignSelf: 'center'
  },
  subTitle: {
    padding: `${spacing(2)}px ${spacing(4)}px`,
    textAlign: 'center',
    alignSelf: 'center'
  },
  title: {
    padding: `0 ${spacing(4)}px`,
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: spacing(4)
  }
}));

const FavoritesEmpty: FC<Props> = ({ title, subTitle, isLoading }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <Box
          display="flex"
          height={200}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Box>
      ) : (
        <>
          <PlaceholderStar className={classes.placeholderStart} />
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subTitle}
          >
            {subTitle}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default FavoritesEmpty;
