import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from '../actions/snackbar';

export type SnackbarActions = ActionType<typeof actions>;

const snackbarReducers = createReducer<UINotification[], SnackbarActions>([])
  .handleAction(actions.enqueueSnackbar, (state, { payload }) => [
    ...state,
    {
      ...payload,
      key: payload.key
    }
  ])
  .handleAction(actions.removeSnackbar, (state, { payload }) =>
    state.filter(
      (notification: UINotification) => notification.key !== payload.key
    )
  );

export default snackbarReducers;
