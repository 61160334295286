import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import apiClient from 'src/utils/api';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { MapLimits } from 'src/types/mapLimits';
import { AVL_SERVER } from 'src/config';
import * as actions from '../actions/mapLimits';

export function* getMapLimits(): SagaIterator {
  const { data }: { data: MapLimits } = yield call(
    apiClient.get,
    `${AVL_SERVER}/map-configuration`
  );

  yield put(actions.getMapLimits.success(data));
}

export default function* MapLimitsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getMapLimits.request),
    safe(getMapLimits, actions.getMapLimits.failure)
  );
}
